<template>
  <v-skeleton-loader
    :loading="loading"
    type="list-item-avatar-two-line"
    class="pa-0"
  >
    <template v-slot:default>
      <!-- :class="ownerOnly ? 'pa-0 mb-4' : 'pa-0 my-4'" -->
      <v-list class="pa-0">
        <v-list-item
          v-for="(account, i) in accounts"
          :key="account._id"
          :class="i === accounts.length - 1 ? 'px-0' : 'px-0 pb-2'"
        >
          <v-list-item-avatar
            :color="account.logo && account.logo.url ? null : 'primary'"
            :tile="!!(account.logo && account.logo.url)"
          >
            <v-img
              contain
              v-if="account.logo && account.logo.url"
              :src="account.logo.url"
            ></v-img>
            <span v-else>{{
              account.info.name
                .split(" ")
                .map((n) => n[0])
                .join("")
            }}</span>
          </v-list-item-avatar>
          <v-list-item-content class="py-0">
            <v-list-item-title>{{ account.info.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ account.email }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="account.role">{{
              $t(account.role)
            }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="account.permissions">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{
                    permissionsToText(account.permissions)
                  }}</span>
                </template>
                <span>{{ permissionsToText(account.permissions) }}</span>
              </v-tooltip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="account.permissions && asset.hasOwnAccess">
            <v-btn icon @click="$emit('view-account-access', account._id)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </v-skeleton-loader>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";

export default {
  name: "AssetAccountList",
  props: ["asset", "ownerOnly"],
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    return { accountStore, assetStore };
  },
  data() {
    return {
      loading: true,
      accounts: [],
      owner: null,
    };
  },
  async created() {
    const resp = await this.assetStore.fetchAssetOwnerAccount(this.asset._id);
    this.owner = resp.data.account;
    this.accounts.push({ ...this.owner, role: "owner" });
    this.accounts.push(...this.asset.access.accounts);
    this.loading = false;
  },
  watch: {
    "asset.access.accounts"() {
      this.accounts = [{ ...this.owner, role: "owner" }];
      this.accounts.push(...this.asset.access.accounts);
    },
  },
  methods: {
    permissionsToText(permissionsArray, separator = ", ") {
      const translatedPermissionsArray = permissionsArray.map((e) =>
        this.$t(e)
      );
      return `${this.$t("permissions")}: ${translatedPermissionsArray.join(separator).toLowerCase()}`;
    },
  },
};
</script>

<style scoped>
:deep(.v-skeleton-loader__list-item-avatar-two-line) {
  padding: 0;
}
</style>
