<template>
  <div
    v-if="fileUploadManagerStore.uploadingStatus"
    v-show="$vuetify.breakpoint.mdAndUp"
    class="wrapper"
  >
    <v-card min-width="300px">
      <v-toolbar flat dark
        >{{
          fileUploadManagerStore.uploadingStatus === "started"
            ? $t("uploadingStarted")
            : fileUploadManagerStore.uploadingStatus === "uploading"
            ? $t("uploadingXOutOfY", {
                x: fileUploadManagerStore.uploadedCount,
                y: fileUploadManagerStore.queueLength,
              })
            : $t("uploadComplete")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="expanded = !expanded">
          <v-icon>{{
            expanded ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </v-btn>
        <v-btn
          icon
          v-if="fileUploadManagerStore.uploadingStatus === 'completed'"
          @click="fileUploadManagerStore.clearQueue()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        v-if="fileUploadManagerStore.uploadingStatus !== 'started' && expanded"
        class="py-2"
      >
        <v-list dense class="pa-0">
          <v-virtual-scroll
            :items="fileUploadManagerStore.queue"
            :height="virtualScrollHeight"
            item-height="40"
            bench="5"
          >
            <template v-slot:default="{ item }">
              <v-list-item>
                <v-list-item-avatar size="24" tile>
                  <v-icon v-if="item.status === 'uploaded'" color="primary"
                    >mdi-check</v-icon
                  >
                  <v-progress-circular
                    v-else
                    :value="item.progress"
                    :indeterminate="!item.progress"
                    size="16"
                    :rotate="!item.progress ? null : -90"
                    :class="item.progress ? 'primary--text' : 'text--disabled'"
                  ></v-progress-circular>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.file.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { useAssetStore } from "@/stores/asset";
import { useFileUploadManagerStore } from "@/stores/file-upload-manager";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "FileUploadManager",
  setup() {
    const assetStore = useAssetStore();
    const fileUploadManagerStore = useFileUploadManagerStore();
    const snackbarStore = useSnackbarStore();
    return { assetStore, fileUploadManagerStore, snackbarStore };
  },
  data() {
    return { expanded: true };
  },
  created() {
    window.addEventListener("beforeunload", this.beforeunloadHandler);
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.beforeunloadHandler);
  },
  computed: {
    virtualScrollHeight() {
      const maxHeight = 250;
      const maxItems = Math.floor(maxHeight / 40);
      return this.fileUploadManagerStore.queue.length <= maxItems
        ? this.fileUploadManagerStore.queue.length * 40
        : maxHeight;
    },
  },
  watch: {
    "fileUploadManagerStore.uploadingStatus"() {
      if (
        this.$vuetify.breakpoint.smAndDown &&
        this.fileUploadManagerStore.uploadingStatus === "completed"
      ) {
        this.snackbarStore.text = this.$t("uploadComplete");
        this.snackbarStore.snackbar = true;
        this.fileUploadManagerStore.clearQueue();
      }
    },
  },
  methods: {
    beforeunloadHandler(e) {
      if (
        !this.fileUploadManagerStore.uploadingStatus ||
        this.fileUploadManagerStore.uploadingStatus === "completed"
      )
        return;
      e.returnValue = "";
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
</style>