<template>
  <div>
    <v-bottom-navigation
      :value="value"
      color="primary"
      grow
      fixed
      background-color="transparent"
      shift
      mandatory
      @change="changeValue"
    >
      <v-btn to="/">
        <span>{{ $t("assets") }}</span>
        <v-icon>mdi-home-variant-outline</v-icon>
      </v-btn>

      <v-btn @click="search = true">
        <span>{{ $t("search") }}</span>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn to="/account">
        <span>{{ $t("account") }}</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-dialog
      :value="search"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      persistent
    >
      <v-card tile>
        <v-card-text class="d-flex pt-4">
          <Search
            :mobile="true"
            :autofocus="true"
            @link-clicked="closeSearch()"
          />
          <v-btn icon @click="closeSearch()" class="close-search-button">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";

import Search from "@/components/Search.vue";

export default {
  name: "BottomNavigation",
  components: {
    Search,
  },
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    return { accountStore, assetStore };
  },
  data() {
    return {
      value: 0,
      search: false,
    };
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.theme = this.$vuetify.theme.dark ? "dark" : "light";
    },
    changeValue(e) {
      this.value = e;
    },
    closeSearch() {
      this.value = this.$route.name === "account" ? 2 : 0;
      this.search = false;
    },
  },
};
</script>

<style scoped>
.close-search-button {
  margin-left: 8px;
  margin-top: 2px;
}
</style>
