<template>
  <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="transparent" flat tile>
          <v-btn
            icon
            @click="
              screen === 'notifications'
                ? $emit('close-dialog')
                : (screen = 'notifications')
            "
          >
            <v-icon>{{
              screen === "notifications" ? "mdi-close" : "mdi-arrow-left"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              screen === "notifications"
                ? $t("notifications")
                : screen === "newNotification"
                ? $t("newNotification")
                : notification.name
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="screen === 'notifications'"
            icon
            @click="screen = 'newNotification'"
          >
            <v-icon>mdi-bell-plus</v-icon>
          </v-btn>
          <v-btn
            v-if="screen === 'newNotification'"
            icon
            :loading="createNotificationLoading"
            :disabled="!valid"
            @click="createNewNotification()"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn
            v-if="screen === 'notification'"
            icon
            :loading="removeNotificationLoading"
            @click="removeNotification()"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-2">
        <div v-show="screen === 'notifications'">
          <NotificatonList
            :assetId="assetId"
            :loadingNotificationId="loadingNotificationId"
            @view-notification="viewNotification"
            @create-new-notification="screen = 'newNotification'"
          />
        </div>
        <div v-if="screen === 'newNotification'">
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="notificationName"
              :label="$t('notificationName')"
              :rules="[rules.required]"
              rounded
              filled
              hide-details
              class="mb-3"
            ></v-text-field>
            <v-textarea
              v-model="notificationMessage"
              :label="$t('message')"
              rounded
              filled
              hide-details
              rows="3"
              auto-grow
              class="mb-3"
            ></v-textarea>
            <v-menu
              v-model="pickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="picker"
                  :label="$t('notificationDate')"
                  :rules="[rules.required]"
                  append-icon="mdi-calendar"
                  readonly
                  rounded
                  filled
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                  class="mb-3"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="picker"
                @input="pickerMenu = false"
                :min="new Date().toISOString()"
                color="primary"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-model="recipientEmail"
              :rules="[rules.required, rules.email]"
              :label="$t('recipientEmail')"
              clearable
              type="email"
              rounded
              filled
            ></v-text-field>
          </v-form>
        </div>
        <div v-if="screen === 'notification'">
          <v-text-field
            :value="notification.name"
            :label="$t('notificationName')"
            rounded
            filled
            hide-details
            readonly
            class="mb-3"
          ></v-text-field>
          <v-textarea
            :value="notification.message"
            :label="$t('message')"
            rounded
            filled
            hide-details
            rows="3"
            auto-grow
            readonly
            class="mb-3"
          ></v-textarea>
          <v-text-field
            v-if="
              !notification.sentTimestamp ||
              notification.sentStatus === 'success'
            "
            :value="
              new Date(
                notification.sentTimestamp
                  ? notification.sentTimestamp
                  : notification.scheduledAtTimestamp
              ).toLocaleString()
            "
            :label="
              notification.sentTimestamp
                ? $t('deliveredOn')
                : $t('scheduledFor')
            "
            readonly
            rounded
            filled
            hide-details
            class="mb-3"
          ></v-text-field>
          <v-text-field
            :value="notification.recipientEmail"
            :label="$t('recipientEmail')"
            rounded
            filled
            readonly
            :persistent-hint="notification.sentTimestamp && notification.sentStatus !== 'success'"
            :hint="notification.sentTimestamp && notification.sentStatus !== 'success' ? $t('failedToDeliverNotification') : null"
          ></v-text-field>
        </div>
      </v-card-text>
    </v-card>
    <ConfirmDialog ref="confirm" />
  </v-dialog>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";
import { useNotificationStore } from "@/stores/notification";
import { useSnackbarStore } from "@/stores/snackbar";
import NotificatonList from "@/components/assets/options/notifications/NotificationList.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

export default {
  name: "AssetsNotifications",
  props: ["dialog", "assetId"],
  components: { NotificatonList, ConfirmDialog },
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    const notificationStore = useNotificationStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, assetStore, notificationStore, snackbarStore };
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t("requiredField"),
        email: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
          this.$t("emailMustBeCorrect"),
      },
      valid: true,
      screen: "notifications",
      loadingNotificationId: null,
      createNotificationLoading: false,
      removeNotificationLoading: false,
      notificationName: `${this.assetStore.selectedAsset.name} ${this.$t(
        "notification"
      ).toLowerCase()}`,
      notificationMessage: "",
      recipientEmail: this.accountStore.account.email,
      pickerMenu: false,
      picker: null,
      notification: {},
    };
  },
  methods: {
    async viewNotification(notificationId) {
      this.loadingNotificationId = notificationId;
      const resp = await this.notificationStore.fetchNotification(
        notificationId
      );
      this.notification = resp.data.notification;
      this.screen = "notification";
      this.loadingNotificationId = null;
    },
    async createNewNotification() {
      try {
        this.createNotificationLoading = true;
        const now = new Date();
        const scheduledAtTimestamp = new Date(this.picker).setHours(
          now.getHours(),
          now.getMinutes()
        );
        const notification = {
          recipientEmail: this.recipientEmail,
          assetId: this.assetId,
          name: this.notificationName,
          message: this.notificationMessage,
          scheduledAtTimestamp,
          language: this.$i18n.locale,
        };
        const resp = await this.notificationStore.createNewNotification(
          notification
        );

        this.createNotificationLoading = false;
        if (resp) {
          this.snackbarStore.text = this.$t("notificationCreatedSuccessfully");
          this.screen = "notifications";
        } else this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
    async removeNotification() {
      try {
        if (
          await this.$refs.confirm.open(
            this.$t("pleaseConfirm"),
            this.$t("areYouSureYouWantToRemoveThisNotification")
          )
        ) {
          this.removeNotificationLoading = true;
          const resp = await this.notificationStore.removeNotification(
            this.notification._id
          );
          this.removeNotificationLoading = false;
          if (resp) {
            this.snackbarStore.text = this.$t(
              "notificationRemovedSuccessfully"
            );
            this.screen = "notifications";
          } else this.snackbarStore.text = this.$t("somethingWentWrong");
          this.snackbarStore.snackbar = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
:deep(.vue-scroll-picker-layer .top),
:deep(.vue-scroll-picker-layer .bottom) {
  border: none;
}

.picker-group {
  display: flex;
}

:deep(.vue-scroll-picker-item.-selected) {
  opacity: 1;
  color: var(--v-primary-base);
}

.vue-scroll-picker {
  height: 5em;
}

.theme--light :deep(.vue-scroll-picker-layer .top) {
  background: linear-gradient(180deg, #fff 1%, rgba(255, 255, 255, 0.65));
  height: calc(50% - 0.6em);
}

.theme--light :deep(.vue-scroll-picker-layer .bottom) {
  background: linear-gradient(0deg, #fff 1%, rgba(255, 255, 255, 0.65));
  height: calc(50% - 0.6em);
}

.theme--dark :deep(.vue-scroll-picker-layer .top) {
  background: linear-gradient(180deg, #1e1e1e 10%, rgba(30, 30, 30, 0.75));
  height: calc(50% - 0.6em);
}

.theme--dark :deep(.vue-scroll-picker-layer .bottom) {
  background: linear-gradient(0deg, #1e1e1e 10%, rgba(30, 30, 30, 0.75));
  height: calc(50% - 0.6em);
}

.nowrap {
  white-space: nowrap;
}
/* Disabled text has an opacity of 38% in light theme and 50% in dark. */
</style>