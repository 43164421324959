<template>
  <v-form ref="form" v-model="validForm" class="pa-0">
    <v-dialog v-model="dialog" max-width="350" @keydown.esc="cancel">
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="input"
            :rules="currentRules"
            :label="inputName"
            rounded
            filled
            hide-details
            single-line
            dense
            autofocus
            clearable
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded @click="cancel">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            rounded
            @click="ok"
            :disabled="!validForm"
            >{{ $t("ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  name: "PromptDialog",
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t("requiredField"),
        integer: (value) =>
          Number.isInteger(Number(value)) || this.$t("mustBeInteger"),
        qrCodesMin: (value) =>
          value >= 1 || this.$t("minimalAmountIs", { amount: 1 }),
        qrCodesMax: (value) =>
          value <= 10000 || this.$t("maximalAmountIs", { amount: 10000 }),
        url: (v) =>
          !v ||
          /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(
            v
          ) ||
          this.$t("invalidUrl"),
        different: (value) =>
          value !== this.initialInput || this.$t("nothingChanged"),
      },
      currentRules: null,
      validForm: false,
      type: "text",
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      inputName: null,
      initialInput: null,
      input: null,
    };
  },
  methods: {
    open(title, inputName, input = null, type = "text") {
      this.type = type;
      switch (type) {
        // case "link":
        //   this.currentRules = [this.rules.url];
        //   break;
        case "assetName":
          this.currentRules = [this.rules.required, this.rules.different];
          break;
        case "qrCodesAmount":
          this.currentRules = [
            this.rules.required,
            this.rules.integer,
            this.rules.qrCodesMin,
            this.rules.qrCodesMax,
          ];
          break;
        default:
          this.currentRules = [];
          break;
      }
      this.dialog = true;
      this.title = title;
      this.inputName = inputName;
      this.initialInput = input;
      this.input = input;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ok() {
      this.resolve(this.input);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>