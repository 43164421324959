<template>
  <div class="overlay">
    <div v-if="!$vuetify.breakpoint.smAndDown" class="illustration"></div>
    <div class="form-wrapper">
      <h2 class="mb-2">{{ $t("registerTitle") }}</h2>
      <p :class="$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mb-8'">
        {{ $t("registerDescription") }}
      </p>
      <v-form ref="form" v-model="valid">
        <v-stepper
          v-model="step"
          elevation="0"
          :vertical="!$vuetify.breakpoint.smAndDown"
          :class="$vuetify.breakpoint.mdAndUp ? 'pb-4' : null"
        >
          <v-stepper-header v-if="$vuetify.breakpoint.smAndDown" elevation="0">
            <v-stepper-step
              :complete="step > 1"
              step="1"
              :class="$vuetify.breakpoint.smAndDown ? 'pl-0' : null"
            >
              {{ $t("enterEmail") }}
            </v-stepper-step>
            <v-divider class="d-none d-sm-block"></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              {{ $t("enterInfo") }}
            </v-stepper-step>
            <v-divider class="d-none d-sm-block"></v-divider>
            <v-stepper-step
              :complete="step > 3"
              step="3"
              :class="$vuetify.breakpoint.smAndDown ? 'pr-0' : null"
            >
              {{ $t("createStrongPassword") }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-step
            v-if="!$vuetify.breakpoint.smAndDown"
            :complete="step > 1"
            step="1"
            class="px-0 pt-0"
          >
            {{ $t("enterEmail") }}
          </v-stepper-step>

          <v-stepper-content
            step="1"
            :class="$vuetify.breakpoint.smAndDown ? 'px-0' : null"
          >
            <v-text-field
              v-model="email"
              :rules="[rules.required, rules.email]"
              :label="`${$t('email')} *`"
              type="email"
              :append-icon="isEmailValid ? 'mdi-arrow-right-circle' : ''"
              @click:append="isEmailValid ? submitEmail(email) : (email = '')"
              rounded
              dense
              filled
            >
            </v-text-field>
          </v-stepper-content>

          <v-stepper-step
            v-if="!$vuetify.breakpoint.smAndDown"
            :complete="step > 2"
            step="2"
            class="px-0"
          >
            {{ $t("enterInfo") }}
          </v-stepper-step>

          <v-stepper-content
            step="2"
            :class="$vuetify.breakpoint.smAndDown ? 'px-0' : null"
          >
            <v-text-field
              v-model="info.name"
              :rules="[rules.required]"
              :label="`${$t('name')} *`"
              rounded
              dense
              filled
            >
            </v-text-field>
            <!-- <v-text-field
              v-model="info.lastName"
              :rules="[rules.required]"
              :label="`${$t('lastName')} *`"
              rounded
              dense
              filled
            >
            </v-text-field> -->
            <v-text-field
              v-model="info.mobile"
              :label="$t('mobile')"
              rounded
              dense
              filled
            >
            </v-text-field>
          </v-stepper-content>

          <v-stepper-step
            v-if="!$vuetify.breakpoint.smAndDown"
            :complete="step > 3"
            step="3"
            class="px-0"
          >
            {{ $t("createStrongPassword") }}
          </v-stepper-step>

          <v-stepper-content
            step="3"
            :class="$vuetify.breakpoint.smAndDown ? 'px-0' : null"
          >
            <v-text-field
              class="d-sm-none"
              v-model="email"
              :label="`${$t('email')} *`"
              type="email"
              append-icon="mdi-pencil"
              @click:append="step = 1"
              rounded
              dense
              filled
              readonly
            >
            </v-text-field>
            <v-text-field
              v-model="password1"
              :rules="[rules.required, rules.min, rules.max, rules.password]"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              :type="show1 ? 'text' : 'password'"
              :label="`${$t('password')} *`"
              rounded
              dense
              filled
            ></v-text-field>
          </v-stepper-content>
        </v-stepper>

        <v-btn
          v-if="step === 1 || step === 2"
          v-on:click="step === 1 ? submitEmail(email) : step++"
          color="primary"
          depressed
          rounded
          :disabled="step === 1 ? !isEmailValid : !info.name"
          class="mb-3"
          >{{ $t("continue") }}</v-btn
        >
        <!-- <v-btn
          v-if="step === 2"
          v-on:click="step++"
          color="primary"
          depressed
          rounded
          class="mb-3"
          >{{ mobile ? $t("continue") : $t("skip") }}</v-btn
        > -->
        <v-btn
          v-if="step === 3"
          v-on:click="register"
          color="primary"
          depressed
          rounded
          :disabled="!valid || snackbar"
          :loading="loading"
          class="mb-3"
          >{{ $t("register") }}</v-btn
        >
        <div class="d-flex">
          <router-link
            v-if="step === 1"
            :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'caption ml-2'"
            style="text-decoration: none"
            to="/login"
            >{{ $t("alreadyHaveAnAccount") }}</router-link
          >
          <v-spacer></v-spacer>
          <a
            v-show="$i18n.locale !== 'sk'"
            class="caption ml-2"
            @click="changeLocale('sk')"
            >Slovenčina</a
          >
          <a
            v-show="$i18n.locale !== 'en'"
            class="caption ml-2"
            @click="changeLocale('en')"
            >English</a
          >
        </div>
      </v-form>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="-1"
      bottom
      transition="slide-y-reverse-transition"
    >
      {{ snackbarText }}
      <template v-slot:action>
        <v-btn text small rounded @click="reload()" color="white">{{
          $t("tryAgain")
        }}</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";

export default {
  name: "RegisterView",
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    return { accountStore, assetStore };
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t("requiredField"),
        min: (v) => v.length >= 8 || this.$t("minChars", { chars: 8 }),
        max: (v) => v.length <= 48 || this.$t("maxChars", { chars: 48 }),
        email: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
          this.$t("emailMustBeCorrect"),
        password: (v) =>
          /^(?=.*([A-Z]){1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,48}$/.test(v) ||
          this.$t("passwordRegex"),
      },
      info: {
        name: "",
        mobile: "",
      },
      valid: true,
      step: 1,
      email: "",
      show1: false,
      password1: "",
      loading: false,
      snackbar: false,
      snackbarText: this.$t("errorDuringRegistration"),
    };
  },
  created() {
    const email = this.$route.query.email;
    if (/.+@.+\..+/.test(email)) {
      this.email = email;
      this.submitEmail(this.email);
    }
  },
  computed: {
    isEmailValid: function () {
      return /.+@.+\..+/.test(this.email);
    },
  },
  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang;
      localStorage.locale = this.$i18n.locale;
    },
    async submitEmail(email) {
      this.step++;

      const formData = new FormData();
      const params = new URLSearchParams(window.location.search);

      formData.append("email", email);
      formData.append("referrer", params.get("referrer") || "");
      formData.append("campaignId", params.get("campaignId") || "");
      formData.append("adsetId", params.get("adsetId") || "");
      formData.append("adId", params.get("adId") || "");

      // const request = new XMLHttpRequest();
      // request.open(
      //   "POST",
      //   "https://script.google.com/macros/s/AKfycbzujzRk7acMcYoiszJHrvReSwIWB9ZRPfbV3jCIDbTeix29ToXXtoDVvyK3TPyp-D6LTw/exec"
      // );
      // request.send(formData);
    },
    async register() {
      this.loading = true;
      const account = {
        email: this.email,
        password: this.password1,
        info: this.info,
        registrationLanguage: this.$i18n.locale,
      };
      const resp = await this.accountStore.register(account);
      if (resp) {
        this.loading = false;
        this.$router.push("/verification");
      } else {
        this.loading = false;
        this.snackbar = true;
      }
    },
    reload() {
      this.step = 1;
      this.email = "";
      this.password1 = "";
      this.snackbar = false;
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  /* align-items: center;
  text-align: center; */
}

.theme--light .overlay {
  background-color: #fff;
}

.theme--dark .overlay {
  background-color: #121212;
}

.illustration {
  background-color: black;
  height: 100%;
  min-width: 50%;
}

.form-wrapper {
  width: fill-available;
  height: auto;
  /* margin: 0 auto; */
  padding: 64px;
}

.form-wrapper .logo {
  margin: 0 auto;
}

.form-wrapper :deep(.v-btn) {
  width: 100%;
}

.form-wrapper .v-stepper {
  background: none;
}

.form-wrapper .v-stepper .v-stepper__header {
  box-shadow: none;
}

.form-wrapper .v-stepper .v-stepper__content {
  padding-top: 16px;
  padding-bottom: 8px;
}

@media only screen and (min-width: 1264px) {
  .illustration {
    min-width: 65%;
  }
}

@media (max-width: 600px) {
  .form-wrapper {
    padding: 32px;
  }
  .form-wrapper .v-stepper .v-stepper__content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 959px) {
  :deep(.v-stepper:not(.v-stepper--vertical))
    .v-stepper__step.v-stepper__step--active
    .v-stepper__label {
    display: flex;
    margin-left: 8px;
  }

  :deep(.v-stepper:not(.v-stepper--vertical))
    .v-stepper__step.v-stepper__step--active {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 599px) {
  .overlay {
    overflow-y: scroll;
    text-align: start;
    align-items: flex-start;
    /* padding-top: 32px; */
  }

  .overlay::-webkit-scrollbar {
    display: none;
  }

  .overlay {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .form-wrapper {
    width: 100%;
  }

  .form-wrapper .logo {
    margin: 0;
  }

  :deep(.v-stepper:not(.v-stepper--vertical)) .v-stepper__step {
    padding-left: 8px;
    padding-right: 8px;
  }

  .form-wrapper :deep(.v-btn) {
    width: 100%;
    margin-bottom: 12px;
  }
}
</style>