var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(this.$vuetify.breakpoint.mdAndUp)?_c('SideNavigation'):_vm._e(),_vm._v(" "),(_vm.$route.name === 'asset')?_c('TopNavigation',{attrs:{"assetLoading":_vm.assetLoading,"showSelectActions":_vm.showSelectActions}}):_vm._e(),_vm._v(" "),_c('v-main',[_c('v-container',{staticClass:"px-5 py-4 pt-0",attrs:{"fluid":""}},[_c('router-view',{attrs:{"save":_vm.save,"showSelectActions":_vm.showSelectActions},on:{"change-asset-loading":function($event){_vm.assetLoading = $event},"change-asset-name":function($event){_vm.assetName = $event},"change-save-button-loading":function($event){_vm.loadingSaveButton = $event},"change-show-select-actions":function($event){_vm.showSelectActions = $event}}})],1)],1),_vm._v(" "),(
      this.$vuetify.breakpoint.smAndDown &&
      !_vm.showSelectActions &&
      _vm.accountStore.isLoggedIn
    )?_c('BottomNavigation'):(
      this.$vuetify.breakpoint.mdAndDown &&
      !_vm.showSelectActions &&
      !_vm.accountStore.isLoggedIn
    )?_c('NotLoggedInMobileFab'):_vm._e(),_vm._v(" "),(_vm.showCreateNewMobile)?_c('CreateNewMobile'):_vm._e(),_vm._v(" "),_c('FileUploadManager'),_vm._v(" "),_c('v-snackbar',{attrs:{"timeout":_vm.snackbarStore.timeout,"bottom":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":"","rounded":""},on:{"click":function($event){_vm.snackbarStore.snackbar = false}}},'v-btn',attrs,false),[_vm._v("\n        "+_vm._s(_vm.$t("close"))+"\n      ")])]}}]),model:{value:(_vm.snackbarStore.snackbar),callback:function ($$v) {_vm.$set(_vm.snackbarStore, "snackbar", $$v)},expression:"snackbarStore.snackbar"}},[_vm._v("\n    "+_vm._s(_vm.snackbarStore.text)+"\n    ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }