<template>
  <div>
    <!-- Not logged in navigation-drawer -->
    <template v-if="!accountStore.isLoggedIn">
      <v-navigation-drawer
        ref="drawer"
        v-model="drawer"
        app
        :width="navigationDrawer.width"
        color="transparent"
        :mobile-breakpoint="960"
        touchless
      >
        <v-subheader style="height: unset" class="mt-5 mb-2">
          {{ $t("navigator") }}
        </v-subheader>
        <div class="px-4" style="overflow-x: auto">
          <FoldersNavigator />
        </div>

        <template v-slot:append>
          <v-list dense rounded>
            <v-list-item to="/login">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("logInOrRegister") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeTheme()">
              <v-list-item-icon>
                <v-icon>{{
                  $vuetify.theme.dark
                    ? "mdi-lightbulb-variant-outline"
                    : "mdi-weather-night"
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                $t($vuetify.theme.dark ? "setLightTheme" : "setDarkTheme")
              }}</v-list-item-title>
            </v-list-item>
            <!-- TODO: Language select -->
            <v-list-item dense>
              <v-list-item-icon>
                <v-icon>mdi-earth</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-chip-group
                  mandatory
                  column
                  v-model="$i18n.locale"
                  @change="changeLocale($i18n.locale)"
                >
                  <v-chip small filter outlined value="sk">SK</v-chip>
                  <v-chip small filter outlined value="en">EN</v-chip>
                </v-chip-group>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
    </template>

    <!-- Default app-bar (desktop) -->
    <template v-else>
      <v-navigation-drawer
        ref="drawer"
        v-model="drawer"
        app
        :width="
          expanded && $route.name === 'asset' ? navigationDrawer.width : 72
        "
        color="transparent"
        :mobile-breakpoint="960"
        touchless
      >
        <v-navigation-drawer
          v-model="drawer"
          absolute
          mini-variant
          mini-variant-width="72"
          color="transparent"
          :mobile-breakpoint="960"
          touchless
        >
          <router-link
            v-if="accountStore.account.logo && accountStore.account.logo.url"
            to="/account"
          >
            <v-avatar class="d-flex text-center mx-auto mt-4" size="44" tile>
              <v-img contain :src="accountStore.account.logo.url"></v-img
            ></v-avatar>
          </router-link>
          <v-btn
            v-else
            x-large
            icon
            outlined
            rounded
            to="/account"
            class="d-flex text-center mx-auto mt-5"
          >
            {{
              accountStore.account.info.name
                .split(" ")
                .map((n) => n[0])
                .join("")
            }}
          </v-btn>

          <v-btn
            large
            icon
            rounded
            @click="$route.fullPath === '/' ? null : $router.push('/')"
            @contextmenu.prevent="expandNavigator()"
            :link="$route.name === 'asset'"
            :input-value="$route.name === 'asset'"
            class="d-flex text-center mx-auto mt-5"
          >
            <v-icon>mdi-home-variant-outline</v-icon>
          </v-btn>

          <div v-if="!accountStore.isSubAccount">
            <v-btn
              large
              icon
              rounded
              to="/team"
              class="d-flex text-center mx-auto mt-5"
            >
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-btn>

            <v-btn
              large
              icon
              rounded
              to="/qrcodes"
              class="d-flex text-center mx-auto mt-5"
            >
              <v-icon>mdi-qrcode</v-icon>
            </v-btn>
          </div>

          <v-spacer></v-spacer>

          <v-btn
            large
            icon
            rounded
            class="d-flex text-center mx-auto mt-5"
            @click="changeTheme()"
          >
            <v-icon>{{
              $vuetify.theme.dark
                ? "mdi-lightbulb-variant-outline"
                : "mdi-weather-night"
            }}</v-icon>
          </v-btn>

          <template v-slot:append>
            <v-btn
              large
              icon
              rounded
              class="d-flex text-center mx-auto mb-5"
              @click="logout()"
              :loading="logoutLoading"
            >
              <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
          </template>
        </v-navigation-drawer>

        <div
          v-show="expanded && $route.name === 'asset'"
          style="padding-left: 72px"
        >
          <Search class="px-4 mt-5" />
          <v-divider class="mx-3 my-5"></v-divider>
          <v-subheader style="height: unset" class="mb-2">
            {{ $t("navigator") }}
            <v-spacer></v-spacer>
            <v-btn icon x-small @click="expanded = false">
              <v-icon>mdi-chevron-double-left</v-icon>
            </v-btn>
          </v-subheader>
          <div class="px-4" style="overflow-x: auto">
            <FoldersNavigator />
          </div>
        </div>
      </v-navigation-drawer>
    </template>
  </div>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";

import Search from "@/components/Search.vue";
import FoldersNavigator from "@/components/folders/FoldersNavigator.vue";

export default {
  name: "SideNavigation",
  components: {
    Search,
    FoldersNavigator,
  },
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    return { accountStore, assetStore };
  },
  data() {
    return {
      drawer: null,
      expanded: true,
      logoutLoading: false,
      navigationDrawer: {
        width: 300,
        minWidth: 300,
        borderSize: 5,
      },
    };
  },
  watch: {
    "$route.name"() {
      if (this.$route.name === "asset") this.expandNavigator();
    },
  },
  mounted() {
    this.setBorderWidth();
    this.setEvents();
  },
  methods: {
    expandNavigator() {
      this.navigationDrawer.width = this.navigationDrawer.minWidth;
      this.expanded = true;
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.theme = this.$vuetify.theme.dark ? "dark" : "light";
    },
    changeLocale(lang) {
      this.$i18n.locale = lang;
      localStorage.locale = this.$i18n.locale;
    },
    setBorderWidth() {
      let i = this.$refs.drawer.$el.querySelector(
        ":scope > .v-navigation-drawer__border"
      );
      i.style.backgroundColor = "transparent";
      i.style.width = this.navigationDrawer.borderSize + "px";
      i.style.cursor = "ew-resize";
      const divNode = document.createElement("div");
      divNode.classList.add("v-navigation-drawer__border");
      i.appendChild(divNode);
    },
    setEvents() {
      const minSize = this.navigationDrawer.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(
        ":scope > .v-navigation-drawer__border"
      );
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        if (f > vm.navigationDrawer.minWidth) el.style.width = f + "px";
      }

      function resizeTouch(e) {
        const x = e.targetTouches[0].pageX;
        let f = direction === "right" ? document.body.scrollWidth - x : x;
        if (f > vm.navigationDrawer.minWidth) el.style.width = f + "px";
      }

      // Mouse
      drawerBorder.addEventListener(
        "mousedown",
        function (e) {
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      document.addEventListener(
        "mouseup",
        function () {
          el.style.transition = "";
          vm.navigationDrawer.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );

      // Touch
      drawerBorder.addEventListener(
        "touchstart",
        function (e) {
          const rect = e.target.getBoundingClientRect();
          const x = e.targetTouches[0].pageX - rect.left;
          if (x < minSize) {
            el.style.transition = "initial";
            document.addEventListener("touchmove", resizeTouch, false);
          }
        },
        false
      );

      document.addEventListener(
        "touchend",
        function () {
          el.style.transition = "";
          vm.navigationDrawer.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("touchmove", resizeTouch, false);
        },
        false
      );
    },
    async logout() {
      this.logoutLoading = true;
      await this.accountStore.logout();
      this.logoutLoading = false;
      this.$router.push("/login");
    },
  },
};
</script>
