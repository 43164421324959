var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list',{class:_vm.dense === false ? 'rounded-t-xl' : null,attrs:{"dense":_vm.dense === false ? false : true}},[_c('v-list-item',[(_vm.assetStore.selected.length === 1)?_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.assetStore.selectedAsset.type === "folder"
            ? "mdi-folder"
            : _vm.getFileIcon(_vm.assetStore.selectedAsset.file.type)))])],1):_vm._e(),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.assetStore.selected.length === 1
            ? _vm.assetStore.selectedAsset.name
            : _vm.$t("xAssets", { assets: _vm.assetStore.selected.length })))])],1)],1),_vm._v(" "),(!_vm.isOptionsListEmpty)?_c('v-divider',{staticClass:"my-2"}):_vm._e(),_vm._v(" "),(_vm.assetStore.selected.length === 1)?_c('div',[_c('v-list-item',{attrs:{"disabled":_vm.qrCodeSvgLoading},on:{"click":function($event){return _vm.generateAssetQrCode()}}},[_c('v-list-item-icon',[(_vm.qrCodeSvgLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"24"}}):_c('v-icon',[_vm._v("mdi-qrcode")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("displayQrCode")))])],1),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.copyAssetLink()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-link")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("copyLink")))])],1),_vm._v(" "),(
          _vm.selectedCapabilities.manageAssetAccess || _vm.accountStore.isLoggedIn
        )?_c('v-divider',{staticClass:"my-2",attrs:{"inset":""}}):_vm._e(),_vm._v(" "),(_vm.selectedCapabilities.manageAssetAccess)?_c('v-list-item',{on:{"click":function($event){_vm.manageAccessDialogMounted = true;
          _vm.manageAccessDialog = true;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-multiple")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("manageAccess")))])],1):_vm._e(),_vm._v(" "),(_vm.accountStore.isLoggedIn)?_c('v-list-item',{on:{"click":function($event){_vm.notificationsDialogMounted = true;
          _vm.notificationsDialog = true;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-bell")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("notifications")))])],1):_vm._e(),_vm._v(" "),(
          _vm.selectedCapabilities.renameAsset ||
          _vm.selectedCapabilities.reassignAssetId ||
          _vm.selectedCapabilities.moveAsset ||
          _vm.selectedCapabilities.removeAsset
        )?_c('v-divider',{staticClass:"my-2",attrs:{"inset":""}}):_vm._e(),_vm._v(" "),(_vm.selectedCapabilities.renameAsset)?_c('v-list-item',{attrs:{"disabled":_vm.renameLoading},on:{"click":function($event){return _vm.renameAsset()}}},[_c('v-list-item-icon',[(_vm.renameLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"24"}}):_c('v-icon',[_vm._v("mdi-rename-box")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("rename")))])],1):_vm._e(),_vm._v(" "),(_vm.selectedCapabilities.reassignAssetId)?_c('v-list-item',{on:{"click":function($event){_vm.qrScanning = true}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-qrcode-scan")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("assignToNewQrCode")))])],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.selectedCapabilities.moveAsset)?_c('v-list-item',{on:{"click":function($event){_vm.moveAssetDialogMounted = true;
        _vm.moveAssetDialog = true;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-folder-move")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("move")))])],1):_vm._e(),_vm._v(" "),(_vm.selectedCapabilities.removeAsset)?_c('v-list-item',{attrs:{"disabled":_vm.removeLoading},on:{"click":_vm.removeSelected}},[_c('v-list-item-icon',[(_vm.removeLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"24"}}):_c('v-icon',[_vm._v("mdi-delete-outline")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("remove")))])],1):_vm._e()],1),_vm._v(" "),_c('ConfirmDialog',{ref:"confirm"}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.qrCodeDialog),callback:function ($$v) {_vm.qrCodeDialog=$$v},expression:"qrCodeDialog"}},[_c('v-img',{attrs:{"src":_vm.qrCodeSvg}})],1),_vm._v(" "),(_vm.manageAccessDialogMounted)?_c('ManageAccess',{key:`ma-${_vm.assetStore.selectedAssetId}`,attrs:{"dialog":_vm.manageAccessDialog,"assetId":_vm.assetStore.selectedAssetId},on:{"close-dialog":function($event){_vm.manageAccessDialog = false;
      _vm.$emit('close-dialog');}}}):_vm._e(),_vm._v(" "),(_vm.notificationsDialogMounted)?_c('Notifications',{key:`n-${_vm.assetStore.selectedAssetId}`,attrs:{"dialog":_vm.notificationsDialog,"assetId":_vm.assetStore.selectedAssetId},on:{"close-dialog":function($event){_vm.notificationsDialog = false;
      _vm.$emit('close-dialog');}}}):_vm._e(),_vm._v(" "),(_vm.moveAssetDialogMounted)?_c('Move',{attrs:{"dialog":_vm.moveAssetDialog},on:{"close-dialog":function($event){_vm.moveAssetDialog = false}}}):_vm._e(),_vm._v(" "),_c('QrScanner',{attrs:{"qrScanning":_vm.qrScanning},on:{"change-qr-scanning":function($event){_vm.qrScanning = $event},"qr-decode":_vm.handleDecodedQr}}),_vm._v(" "),_c('PromptDialog',{ref:"prompt"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }