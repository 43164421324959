<template>
  <div>
    <div
      @dragover.prevent="onDragover"
      @dragleave="onDragleave"
      @drop.prevent="onDrop"
      :class="isDragging ? 'active' : ''"
    >
      <slot></slot>
    </div>
    <input
      type="file"
      ref="fileInput"
      multiple
      @change="onBrowseSelect"
      class="d-none"
    />
    <v-snackbar
      v-model="isDragging"
      bottom
      transition="slide-y-reverse-transition"
    >
      {{
        $t("uploadFilesToFolderX", {
          folder: assetStore.parentAssetId
            ? assetStore.parentAsset.name
            : $t("rootDirectory").toLowerCase(),
        })
      }}
    </v-snackbar>
  </div>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";
import { useFileUploadManagerStore } from "@/stores/file-upload-manager";
import { useSnackbarStore } from "@/stores/snackbar";
import { nanoid } from "nanoid";

export default {
  name: "DropZone",
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    const fileUploadManagerStore = useFileUploadManagerStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, assetStore, fileUploadManagerStore, snackbarStore };
  },
  data() {
    return {
      isDragging: false,
      timeout: null,
      fileInputWebkitDirectory: false,
    };
  },
  computed: {
    canCreateAsset() {
      try {
        return this.assetStore.parentAssetId
          ? this.assetStore.parentAsset.capabilities.createAsset
          : this.accountStore.checkAccountPermissions(
              "createAsset",
              this.assetStore.path
            );
      } catch (error) {
        return false;
      }
    },
  },
  methods: {
    browse(type) {
      if (!this.canCreateAsset) return;
      if (type === "folder")
        this.$refs.fileInput.setAttribute("webkitdirectory", true);
      else this.$refs.fileInput.removeAttribute("webkitdirectory");
      this.$refs.fileInput.click();
    },
    onDragover() {
      if (!this.canCreateAsset) return;
      this.isDragging = true;
      clearTimeout(this.timeout);
    },
    onDragleave() {
      if (!this.canCreateAsset) return;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.isDragging = false;
      }, 250);
    },
    async onDrop(e) {
      let lastFilePath;
      try {
        if (!this.canCreateAsset) return;
        this.isDragging = false;
        const items = e.dataTransfer.items;
        const assets = [];
        const files = [];

        const scanFiles = async (item, temporaryParentAssetId = null) => {
          lastFilePath = item.fullPath;
          if (item.isFile) {
            const temporaryId = nanoid();
            const file = await new Promise((resolve, reject) => {
              item.file(resolve, reject);
            });
            files.push({ temporaryId, file });
            assets.push({
              temporaryId,
              temporaryParentAssetId,
              type: "file",
              name: item.name,
              file: {
                name: file.name,
                type: file.type,
                size: file.size,
              },
            });
          }

          if (item.isDirectory) {
            const temporaryId = nanoid();
            assets.push({
              temporaryId,
              temporaryParentAssetId,
              type: "folder",
              name: item.name,
            });
            const directoryReader = item.createReader();
            const entries = await new Promise((resolve, reject) =>
              directoryReader.readEntries(resolve, reject)
            );
            for (let index = 0; index < entries.length; index++) {
              const entry = entries[index];
              await scanFiles(entry, temporaryId);
            }
          }
        };

        const loop = () => {
          const resArr = [];
          for (let i = 0; i < items.length; i++) {
            let item = items[i].webkitGetAsEntry();
            if (item) {
              let res = scanFiles(item, this.assetStore.parentAssetId);
              resArr.push(res);
            }
          }
          return resArr;
        };

        let resArr = loop();
        await Promise.all(resArr);

        console.log(files);

        if (files.length > 0) {
          this.fileUploadManagerStore.uploadingStatus = "started";

          const resp = await this.assetStore.createNewAssets(
            assets,
            this.assetStore.parentAssetId
          );
          if (resp) {
            await this.fileUploadManagerStore.enqueueFiles(
              files,
              resp.data.presignedPosts
            );
            this.fileUploadManagerStore.performUpload();
          }
        }
      } catch (error) {
        if (error instanceof DOMException) {
          let folderOriginalName;
          try {
            folderOriginalName = `${lastFilePath
              .match(/[^/]+/)[0]
              .slice(0, 25)}...`;
          } catch (error) {
            folderOriginalName = "";
          }
          this.snackbarStore.text = this.$t("filePathTooLongError", {
            path: lastFilePath,
            folderOriginalName,
            suggestedName: "x",
          });
        } else this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      }
    },
    async onBrowseSelect(e) {
      try {
        if (!this.canCreateAsset) return;
        const items = e.target.files;

        const assets = [];
        const folderTemporaryIdsMap = new Map();
        const files = [];

        for (let index = 0; index < items.length; index++) {
          const file = items[index];
          let parentAssetId = this.assetStore.parentAssetId;

          if (file.webkitRelativePath) {
            const splittedPaths = file.webkitRelativePath
              .split("/")
              .slice(0, -1);
            for (let i = 0; i < splittedPaths.length; i++) {
              const splittedPath = splittedPaths[i];
              const splittedPathKey = splittedPaths.slice(0, i + 1).join("/");
              if (!folderTemporaryIdsMap.get(splittedPathKey)) {
                const temporaryId = nanoid();
                const temporaryParentAssetId =
                  folderTemporaryIdsMap.get(
                    splittedPaths.slice(0, i).join("/")
                  ) || this.assetStore.parentAssetId;
                folderTemporaryIdsMap.set(splittedPathKey, temporaryId);
                assets.push({
                  temporaryId,
                  temporaryParentAssetId,
                  type: "folder",
                  name: splittedPath,
                });
              }
            }
            parentAssetId = folderTemporaryIdsMap.get(
              file.webkitRelativePath.split("/").slice(0, -1).join("/")
            );
          }

          const temporaryId = nanoid();
          files.push({ temporaryId, file });
          assets.push({
            temporaryId,
            temporaryParentAssetId: parentAssetId,
            type: "file",
            name: file.name,
            file: {
              name: file.name,
              type: file.type,
              size: file.size,
            },
          });
        }

        e.target.value = null;

        console.log(files);

        if (files.length > 0) {
          this.fileUploadManagerStore.uploadingStatus = "started";

          const resp = await this.assetStore.createNewAssets(
            assets,
            this.assetStore.parentAssetId
          );
          if (resp) {
            await this.fileUploadManagerStore.enqueueFiles(
              files,
              resp.data.presignedPosts
            );
            this.fileUploadManagerStore.performUpload();
          }
        }
      } catch (error) {
        this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      }
    },
  },
};
</script>

<style scoped>
.theme--light .active {
  background: rgba(99, 200, 148, 0.08) !important;
  outline: 1px solid var(--v-primary-base);
}

.theme--dark .active {
  background: rgba(99, 200, 148, 0.08) !important;
  outline: 1px solid var(--v-primary-base);
}
</style>