<template>
  <div class="d-flex flex-nowrap">
    <v-btn
      small
      outlined
      rounded
      class="ml-4 mr-2"
      color="primary"
      :disabled="disabled"
      @click="newFolderDialog = true"
    >
      <v-icon left>mdi-folder-plus</v-icon>
      {{ $t("newFolder") }}
    </v-btn>
    <v-menu
      offset-y
      transition="slide-y-transition"
      rounded="lg"
      :nudge-top="-4"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          small
          outlined
          rounded
          color="primary"
          :disabled="disabled"
        >
          <v-icon left>mdi-upload</v-icon>
          {{ $t("uploadAssets") }}
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="$refs.dropzone.browse('files')">
          <v-list-item-icon>
            <v-icon>mdi-file-upload</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("uploadFiles") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$refs.dropzone.browse('folder')">
          <v-list-item-icon>
            <v-icon>mdi-folder-upload</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("uploadFolder") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <DropZone ref="dropzone" />
    <!-- <v-menu
      offset-y
      transition="slide-y-transition"
      rounded="lg"
      :nudge-top="-4"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn small outlined rounded color="primary" v-bind="attrs" v-on="on">
          <v-icon left>mdi-plus</v-icon>
          {{ $t("uploadAssets") }}
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          @click="
            $router.push({
              path: '/assets/new',
              query: { ...$route.query },
            })
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-note-text</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("blankAsset") }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="
            templateSelectDialogMounted = true;
            templateSelectDialog = true;
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-format-float-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("assetFromTemplate") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="qrScanning = true">
          <v-list-item-icon>
            <v-icon>mdi-qrcode</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("assetFromQr") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
    <NewFolderDialog
      :dialog="newFolderDialog"
      :loading="newFolderDialogLoading"
      @create-new-folder="createNewFolder"
      @close-dialog="newFolderDialog = false"
    />
  </div>
</template>

<script>
import { useAssetStore } from "@/stores/asset";
import { useSnackbarStore } from "@/stores/snackbar";
import NewFolderDialog from "@/components/dialogs/NewFolderDialog.vue";
import DropZone from "@/components/files/DropZone.vue";

export default {
  name: "CreateNewDesktop",
  props: ["disabled"],
  components: {
    NewFolderDialog,
    DropZone,
  },
  setup() {
    const assetStore = useAssetStore();
    const snackbarStore = useSnackbarStore();
    return { assetStore, snackbarStore };
  },
  data() {
    return {
      newFolderDialog: false,
      newFolderDialogLoading: false,
      qrScanning: false,
      templateSelectDialogMounted: false,
      templateSelectDialog: false,
    };
  },
  methods: {
    async createNewFolder(folderName) {
      this.newFolderDialogLoading = true;
      const parentAssetId = this.assetStore.parentAssetId;

      const folder = {
        type: "folder",
        name: folderName,
        temporaryParentAssetId: parentAssetId,
      };

      const resp = await this.assetStore.createNewAssets(
        [folder],
        parentAssetId
      );
      if (resp) this.snackbarStore.text = this.$t("folderCreatedSuccessfully");
      else this.snackbarStore.text = this.$t("somethingWentWrong");
      this.newFolderDialogLoading = false;
      this.newFolderDialog = false;
      this.snackbarStore.snackbar = true;
    },
  },
};
</script>