<template>
  <v-app-bar flat app color="transparent">
    <!-- <v-btn
          icon
          small
          outlined
          rounded
          class="mr-4"
          color="primary"
          @click="$router.go(-1)"
        >
          <v-icon>mdi-chevron-left</v-icon>
          {{ $t("back") }}
          <v-icon small>mdi-arrow-left</v-icon>
        </v-btn> -->

    <!-- Mobile selecting app-bar -->
    <template v-if="$vuetify.breakpoint.smAndDown && showSelectActions">
      <v-app-bar-nav-icon @click="assetStore.selected = []">
        <v-icon>mdi-close</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        {{ $t("itemsSelected", { items: assetStore.selected.length }) }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="selectAll()">
        <v-icon>mdi-select-all</v-icon>
      </v-btn>
      <v-bottom-sheet key="selectSheet" v-model="selectSheet">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon class="ml-1">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-sheet rounded="t-xl">
          <AssetOptionsList
            :dense="false"
            @close-dialog="selectSheet = false"
          />
        </v-sheet>
      </v-bottom-sheet>
    </template>

    <!-- Default app-bar -->
    <template v-else>
      <!-- <v-app-bar-nav-icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon> -->

      <!-- <v-toolbar-title>
        {{ $t("assets") }}
      </v-toolbar-title> -->

      <img
        v-if="$vuetify.theme.dark"
        src="@/assets/logo-white.svg"
        height="24"
        class="mr-4"
      />
      <img v-else src="@/assets/logo-grey.svg" height="24" class="mr-4" />
      <div class="breadcrumbs-wrapper" @wheel="wheelScroll($event)">
        <div>
          <v-breadcrumbs large :items="breadcrumbs" class="pa-0">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :to="item.to"
                :exact="item.exact"
                :exact-path="item.exactPath"
                :class="item.disabled ? 'text--disabled' : null"
              >
                <v-progress-circular
                  v-if="item.nameLoading"
                  indeterminate
                  size="16"
                ></v-progress-circular>
                <span v-else>{{ item.name }}</span>
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
          <v-menu
            v-if="$vuetify.breakpoint.mdAndUp"
            v-model="showMenu"
            offset-y
            transition="slide-y-transition"
            rounded="lg"
            :nudge-top="-4"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="assetStore.parentAssetId && !assetLoading"
                v-bind="attrs"
                v-on="on"
                icon
                small
                class="ml-1"
                @click="assetStore.selected = [assetStore.parentAssetId]"
              >
                <v-icon small>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <AssetOptionsList @close-dialog="showMenu = false" />
          </v-menu>
          <v-bottom-sheet
            v-else-if="$vuetify.breakpoint.smAndDown"
            v-model="sheet"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="assetStore.parentAssetId && !assetLoading"
                v-bind="attrs"
                v-on="on"
                icon
                small
                class="ml-1"
                @click="assetStore.selected = [assetStore.parentAssetId]"
              >
                <v-icon small>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-sheet rounded="t-xl">
              <AssetOptionsList :dense="false" @close-dialog="sheet = false" />
            </v-sheet>
          </v-bottom-sheet>
        </div>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex flex-nowrap">
        <div
          v-if="
            $vuetify.breakpoint.smAndDown &&
            fileUploadManagerStore.uploadingStatus
          "
        >
          <v-chip color="primary" pill small class="ml-2">
            <v-icon small :left="!!fileUploadManagerStore.queueLength"
              >mdi-upload</v-icon
            >
            <span v-if="fileUploadManagerStore.queueLength">
              {{ fileUploadManagerStore.uploadedCount }} /
              {{ fileUploadManagerStore.queueLength }}
            </span>
          </v-chip>
        </div>
        <CreateNewDesktop
          v-if="
            $route.name === 'asset' &&
            $vuetify.breakpoint.mdAndUp &&
            (assetStore.parentAssetId
              ? assetStore.parentAsset.capabilities.createAsset
              : accountStore.checkAccountPermissions(
                  'createAsset',
                  assetStore.path
                ))
          "
          :disabled="assetLoading"
        />

        <v-menu
          v-if="$vuetify.breakpoint.mdAndUp"
          v-model="showMenu2"
          offset-y
          transition="slide-y-transition"
          rounded="lg"
          :nudge-top="-4"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              outlined
              class="ml-2"
              color="primary"
              :disabled="assetLoading || assetStore.selected.length === 0"
            >
              <v-icon small>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <AssetOptionsList @close-dialog="showMenu2 = false" />
        </v-menu>
      </div>
    </template>
  </v-app-bar>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";
import { useFileUploadManagerStore } from "@/stores/file-upload-manager";
import { useSnackbarStore } from "@/stores/snackbar";

import CreateNewDesktop from "@/components/assets/CreateNewDesktop.vue";
import AssetOptionsList from "@/components/assets/options/AssetOptionsList.vue";

export default {
  name: "TopNavigation",
  props: ["assetLoading", "showSelectActions"],
  components: {
    CreateNewDesktop,
    AssetOptionsList,
  },
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    const fileUploadManagerStore = useFileUploadManagerStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, assetStore, fileUploadManagerStore, snackbarStore };
  },
  data() {
    return {
      showMenu: false,
      showMenu2: false,
      sheet: false,
      selectSheet: false,
    };
  },
  computed: {
    breadcrumbs() {
      let breadcrumbs = [];
      if (this.assetStore.isParentAsset3rdParty())
        breadcrumbs.push({
          name: this.$t("shared"),
          disabled: true,
        });
      else
        breadcrumbs.push({
          name: this.$t("assets"),
          to: "/",
          exact: true,
          disabled: true,
        });

      const path = this.assetStore.path;
      const folderIds = path.split("/");
      let bool = false;

      for (let index = 0; index < folderIds.length; index++) {
        if (!this.$route.params.id) {
          bool = true;
          break;
        }
        const id = folderIds[index];
        const assetIndex = this.assetStore.cachedAssetNames.findIndex(
          (asset) => asset._id === id
        );
        const assetName =
          assetIndex > -1
            ? this.assetStore.cachedAssetNames[assetIndex].name
            : null;
        const name =
          this.assetStore.parentAssetId === id
            ? this.assetStore.parentAsset.name
            : assetName;
        const to = `/${id}`;
        breadcrumbs.push({
          name,
          to,
          icon: "mdi-folder",
          exact: true,
        });
        if (id === this.$route.params.id) {
          bool = true;
          break;
        }
      }

      breadcrumbs = breadcrumbs.filter((e) => e.name);

      if (this.assetLoading && !bool)
        breadcrumbs.push({
          nameLoading: true,
          to: `/${this.$route.params.id}`,
          exact: false,
          exactPath: true,
        });

      return breadcrumbs;
    },
  },
  watch: {
    showMenu() {
      if (this.showMenu === false) this.clearSelection();
    },
    sheet() {
      if (this.sheet === false) this.clearSelection();
    },
  },
  methods: {
    wheelScroll(event) {
      event.currentTarget.scrollLeft += event.deltaY;
    },
    clearSelection() {
      this.assetStore.selected = [];
    },
    selectAll() {
      this.assetStore.selected = this.assetStore.childAssets.map(
        (item) => item._id
      );
      this.snackbarStore.text = this.$t("xAssetsWereSelected", {
        assets: this.assetStore.selected.length,
      });
      this.snackbarStore.snackbar = true;
    },
  },
};
</script>

<style scoped>
.breadcrumbs-wrapper {
  display: flex;
  overflow-x: scroll;
  flex-wrap: unset;
  direction: rtl;
  line-height: 28px;
}

.breadcrumbs-wrapper div {
  display: flex;
  flex-wrap: unset;
  direction: ltr;
  line-height: 28px;
}

.breadcrumbs-wrapper::-webkit-scrollbar {
  display: none;
}

.breadcrumbs-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.v-breadcrumbs {
  display: flex;
  flex-wrap: nowrap;
  direction: ltr;
}

.v-breadcrumbs li {
  white-space: nowrap;
}
</style>