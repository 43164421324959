import { defineStore } from "pinia"
import axios from "axios"
import { wait } from "@/util/helpers";

export const useFileUploadManagerStore = defineStore("fileUploadManager", {
    state: () => ({
        maxConcurrentUploads: 10,
        uploadingStatus: null,
        queue: [],
    }),
    getters: {
        concurrentUploads: state => state.queue.reduce((counter, { status }) => status === "uploading" < 100 ? counter += 1 : counter, 0),
        queueLength: state => state.queue.length,
        uploadedCount: state => state.queue.reduce((counter, upload) => {
            if (upload.status === "uploaded") counter += 1
            return counter;
        }, 0)
    },
    actions: {
        clearQueue() {
            this.queue = []
            this.uploadingStatus = null
        },
        async enqueueFiles(files, presignedPosts) {
            try {
                for (let i = 0; i < presignedPosts.length; i++) {
                    const presignedPost = presignedPosts[i];
                    const file = files.find(e => e.temporaryId === presignedPost.temporaryId)
                    if (file) {
                        this.queue.push({
                            id: presignedPost.id,
                            temporaryId: presignedPost.id,
                            file: file.file,
                            status: "pending",
                            presignedPost: presignedPost.presignedPost,
                            progress: null
                        })
                    }
                }
                // presignedPosts.forEach(presignedPost => {
                //     const file = files.find(e => e.temporaryId === presignedPost.temporaryId)
                //     if (file) {
                //         this.queue.push({
                //             id: presignedPost.id,
                //             temporaryId: presignedPost.id,
                //             file: file.file,
                //             status: "pending",
                //             presignedPost: presignedPost.presignedPost,
                //             progress: null
                //         })
                //     }
                // })
            }
            catch (error) {
                console.log(error)
            }
        },
        // async uploadQueue() {
        //     if (this.uploading) return
        //     else this.uploading = true

        //     while (this.uploading && this.queue.some(e => e.status === "pending")) {
        //         await this.performUpload()
        //         // if (this.concurrentUploads < this.maxConcurrentUploads) {
        //         //     this.performUpload()
        //         // }
        //     }

        //     this.uploadComplete = true
        // },
        async performUpload(retryCount = 0) {
            try {
                const index = this.queue.findIndex(e => e.status === "pending")
                if (index > -1) {
                    this.uploadingStatus = "uploading"
                    const upload = this.queue[index]
                    upload.status = "uploading"
                    upload.controller = new AbortController();
                    const formData = new FormData();
                    Object.keys(upload.presignedPost.fields).forEach((key) =>
                        formData.append(key, upload.presignedPost.fields[key])
                    );
                    formData.append("file", upload.file);

                    try {
                        await axios.request({
                            method: "POST",
                            url: upload.presignedPost.url,
                            data: formData,
                            withCredentials: false,
                            onUploadProgress: (p) => {
                                upload.progress = (p.loaded / p.total) * 100;
                            },
                            signal: upload.controller.signal,
                        });
                        upload.status = "uploaded"
                        this.performUpload()
                    } catch (error) {
                        upload.status = "pending"
                        upload.progress = null
                        await wait(2 ** retryCount * 100);
                        this.performUpload(Math.min(retryCount + 1, 8))
                    }
                } else {
                    return this.uploadingStatus = "completed"
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        isUploading(assetId) {
            if (this.queue.length === 0) return false
            const upload = this.queue.find(e => e.id === assetId)
            if (!upload) return false
            return upload.status !== "uploaded"
        },
        getProgress(assetId) {
            const upload = this.queue.find(e => e.id === assetId)
            if (!upload) return null
            return upload.progress
        }
    },
})