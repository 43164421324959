<template>
  <div class="search-wrapper">
    <v-autocomplete
      v-model="select"
      :search-input.sync="search"
      :label="`${$t('search')}...`"
      :placeholder="`${$t('search')}...`"
      :menu-props="{
        transition: 'slide-y-transition',
        rounded: 'lg',
        nudgeTop: -4,
        maxHeight: 'auto',
        minWidth: 196,
        bottom: true,
        closeOnContentClick: true,
      }"
      item-text="name"
      filled
      flat
      dense
      rounded
      hide-details
      single-line
      auto-select-first
      elevation="0"
      :autofocus="autofocus === true ? true : false"
    >
      <template v-slot:prepend-inner>
        <!-- :color="
          $vuetify.theme.dark || $vuetify.breakpoint.smAndDown
            ? 'white'
            : 'primary'
        " -->
        <v-progress-circular
          v-if="loading"
          indeterminate
          :size="24"
          class="mr-2"
          :color="$vuetify.theme.dark ? 'white' : 'rgba(0,0,0,.54)'"
        ></v-progress-circular>
        <v-icon v-else class="mr-2">mdi-magnify</v-icon>
      </template>
      <!-- <template v-slot:prepend-item>
      <v-chip-group
        v-model="labelsFilter"
        active-class="primary--text"
        class="px-4"
        @change="querySelections(search)"
      >
        <v-chip small pill value="label-01">
          {{ $t("label-01") }}
        </v-chip>
        <v-chip small pill value="label-02">
          {{ $t("label-02") }}
        </v-chip>
      </v-chip-group>
    </template> -->
      <template v-slot:no-data>
        <div v-if="items.length === 0" class="no-data-text pa-4 py-3">
          {{ $t("noData") }}
        </div>
        <div v-else>
          <v-virtual-scroll
            :items="items"
            :height="virtualScrollHeight"
            width="500"
            bench="5"
            :item-height="mobile === true ? 56 : 40"
          >
            <template v-slot:default="{ item }">
              <v-list-item
                :key="item._id"
                replace
                link
                :to="`/${item._id}`"
                @click="$emit('link-clicked')"
              >
                <v-icon :small="mobile === true ? false : true" class="mr-2">{{
                  item.type === "folder" ? "mdi-folder" : "mdi-file"
                }}</v-icon>
                <span v-html="highlight(item.name, search)"></span>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </div>
      </template>
      <template v-slot:append>
        <v-icon
          :small="mobile === true ? false : true"
          :class="
            mobile === true ? 'qr-scanning-button mobile' : 'qr-scanning-button'
          "
          @click="qrScanning = true"
          >mdi-qrcode</v-icon
        >
      </template>
    </v-autocomplete>
    <QrScanner
      :qrScanning="qrScanning"
      @change-qr-scanning="qrScanning = $event"
      @qr-decode="handleDecodedQr"
    />
  </div>
</template>

<script>
import { useAssetStore } from "@/stores/asset";
import QrScanner from "@/components/QrScanner.vue";

export default {
  name: "SearchComponent",
  props: ["mobile", "autofocus"],
  components: {
    QrScanner,
  },
  setup() {
    const store = useAssetStore();
    return { store };
  },
  data() {
    return {
      timeout1: null,
      timeout2: null,
      loading: false,
      items: [],
      search: null,
      select: null,
      labelsFilter: null,
      qrScanning: false,
    };
  },
  computed: {
    virtualScrollHeight() {
      if (this.mobile === true)
        return this.$vuetify.breakpoint.height - 32 - 56 - 4;
      const maxHeight = (this.$vuetify.breakpoint.height - 56 - 16) * 0.75;
      const maxItems = Math.floor(maxHeight / 40);
      return this.items.length <= maxItems ? this.items.length * 40 : maxHeight;
    },
  },
  watch: {
    search(val) {
      //   val && val !== this.select && this.querySelections(val);
      val !== this.select && this.querySelections(val);
    },
  },
  methods: {
    querySelections: async function (v) {
      this.loading = true;
      clearTimeout(this.timeout1);
      clearTimeout(this.timeout2);
      const self = this;

      if (v) {
        // Search local store
        this.timeout1 = setTimeout(function () {
          const items = [];
          const assets = self.store.cachedAssetNames.filter((e) => {
            return (
              (e.name || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
            );
          });
          if (assets.length > 0) items.push(...assets);

          self.items = items;
        }, 250);

        // Query server
        this.timeout2 = setTimeout(async function () {
          const resp = await self.store.queryAssets(v);
          const assets = resp.data.assets;
          assets.forEach((asset) => {
            const assetIndex = self.items.findIndex((e) => e._id === asset._id);
            if (assetIndex === -1) self.items.push(asset);
          });

          self.loading = false;
        }, 2500);
      } else this.loading = false;
    },
    handleDecodedQr(result) {
      try {
        this.qrScanning = false;
        this.$router.push(result.match(/\w+$/)[0]);
      } catch {
        console.log("Invalid QR code");
      }
    },
    highlight(value, query) {
      return value.replace(
        new RegExp(query, "ig"),
        "<span class='v-list-item__mask'>" + query + "</span>"
      );
    },
  },
};
</script>

<style scoped>
:deep(.v-input__append-inner:has(button.qr-scanning-button:not(.mobile))) {
  margin-top: 12px !important;
}

.no-data-text {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
}

@media (max-width: 600px) {
  .search-wrapper {
    width: 100%;
  }
}
</style>