import { defineStore } from 'pinia'
import axios from "axios"
import localforage from 'localforage'
import { API_URL } from '../api.js'
import { pick, flattenObject, diffFlatten, unflatenObject } from "../util/helpers";
import { useAssetStore } from "@/stores/asset";

export const useNotificationStore = defineStore("notification", {
    state: () => ({
        notifications: [],
        lastSynced: null,
    }),
    getters: {
        currentAssetNotifications: (state) => {
            const assetStore = useAssetStore()
            const activeNotifications = state.notifications.filter(notification => notification.assetId === assetStore.selectedAssetId && !notification.sentTimestamp).sort((a, b) => a.scheduledAtTimestamp - b.scheduledAtTimestamp);
            const inactiveNotifications = state.notifications.filter(notification => notification.assetId === assetStore.selectedAssetId && notification.sentTimestamp).sort((a, b) => a.scheduledAtTimestamp - b.scheduledAtTimestamp);
            return activeNotifications.concat(inactiveNotifications)
        },
    },
    actions: {
        async persist() {
            await localforage.setItem("notificationStore", this.$state)
        },
        async createNewNotification(notification) {
            try {
                const resp = await axios.post(`${API_URL}/notifications`, notification, { withCredentials: true })
                const data = resp.data

                this.notifications.push(pick(data.notification, ["_id", "assetId", "scheduledAtTimestamp", "sentTimestamp", "name"]))
                await this.persist()

                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async queryNotifications(assetId = null) {
            try {
                const resp = await axios.get(`${API_URL}/notifications?assetId=${assetId}`, { withCredentials: true })
                const data = resp.data;
                const notificationIds = []

                data.notifications.forEach(notification => {
                    notificationIds.push(notification._id)
                    const notificationIndex = this.notifications.findIndex(e => e._id === notification._id)
                    if (notificationIndex === -1) this.notifications.push(notification)
                    else this.notifications[notificationIndex] = notification
                });

                if (!assetId) this.lastSynced = Date.now()
                else this.notifications = this.notifications.filter(notification => notification.assetId === assetId && notificationIds.includes(notification._id))
                await this.persist()

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchNotification(notificationId) {
            try {
                const resp = await axios.get(`${API_URL}/notifications/${notificationId}`, { withCredentials: true })
                const data = resp.data;

                const notification = pick(data.notification, ["_id", "assetId", "scheduledAtTimestamp", "sentTimestamp", "name"])

                const notificationIndex = this.notifications.findIndex(e => e._id === notificationId)
                if (notificationIndex === -1) this.notifications.push(notification)
                else this.notifications[notificationIndex] = notification
                await this.persist()

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async updateNotification(notification, initialNotification = null) {
            try {
                const notificationIndex = this.notifications.findIndex(e => e._id === notification._id)
                const flatOldNotification = flattenObject(initialNotification ? initialNotification : this.notifications[notificationIndex])
                const flatNewNotification = flattenObject(notification)
                const flatDiff = diffFlatten(flatOldNotification, flatNewNotification)
                const resp = await axios.patch(`${API_URL}/notifications/${notification._id}`, flatDiff, { withCredentials: true })

                const flatUpdatedNotification = flatOldNotification
                for (const key in flatDiff) {
                    flatUpdatedNotification[key] = flatDiff[key]
                }
                const updatedNotification = unflatenObject(flatUpdatedNotification)
                this.notifications[notificationIndex] = pick(updatedNotification, ["_id", "assetId", "scheduledAtTimestamp", "sentTimestamp", "name"])
                await this.persist()

                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async removeNotification(notificationId) {
            try {
                const resp = await axios.delete(`${API_URL}/notifications/${notificationId}`, { withCredentials: true })
                const notificationIndex = this.notifications.findIndex(e => e._id === notificationId)

                if (notificationIndex > -1) {
                    this.notifications.splice(notificationIndex, 1)
                    await this.persist()
                }

                return resp
            } catch (error) {
                console.log(error)
            }
        },
    },
})