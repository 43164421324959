<template>
  <div>
    <v-bottom-sheet v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <div class="fab-wrapper">
          <v-btn
            v-bind="attrs"
            v-on="on"
            outlined
            rounded
            color="primary"
            large
            :disabled="disabled"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t("new") }}
          </v-btn>
        </div>
      </template>
      <v-sheet class="text-center px-8 py-4" rounded="t-xl">
        <div class="overline mb-4">{{ $t("createOrUpload") }}</div>
        <div class="d-flex align-start justify-space-between">
          <div class="btn-wrapper">
            <v-btn
              outlined
              fab
              elevation="0"
              color="primary"
              class="mb-2"
              @click="
                newFolderDialog = true;
                sheet = false;
              "
            >
              <div>
                <v-icon>mdi-folder-plus</v-icon>
              </div>
            </v-btn>
            <div class="caption">{{ $t("createFolder") }}</div>
          </div>
          <div class="btn-wrapper">
            <v-btn
              outlined
              fab
              elevation="0"
              color="primary"
              class="mb-2"
              @click="
                $refs.dropzone.browse('files');
                sheet = false;
              "
            >
              <div>
                <v-icon>mdi-file-upload</v-icon>
              </div>
            </v-btn>
            <div class="caption">{{ $t("uploadFiles") }}</div>
          </div>
          <div class="btn-wrapper">
            <v-btn
              outlined
              fab
              elevation="0"
              color="primary"
              class="mb-2"
              @click="
                $refs.dropzone.browse('folder');
                sheet = false;
              "
            >
              <div>
                <v-icon>mdi-folder-upload</v-icon>
              </div>
            </v-btn>
            <div class="caption">{{ $t("uploadFolder") }}</div>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <DropZone ref="dropzone" />
    <NewFolderDialog
      :dialog="newFolderDialog"
      :loading="newFolderDialogLoading"
      @create-new-folder="createNewFolder"
      @close-dialog="newFolderDialog = false"
    />
  </div>
</template>

<script>
import { useAssetStore } from "@/stores/asset";
import { useSnackbarStore } from "@/stores/snackbar";
import NewFolderDialog from "@/components/dialogs/NewFolderDialog.vue";
import DropZone from "@/components/files/DropZone.vue";

export default {
  name: "CreateNewMobile",
  props: ["disabled"],
  components: {
    DropZone,
    NewFolderDialog,
  },
  setup() {
    const assetStore = useAssetStore();
    const snackbarStore = useSnackbarStore();
    return { assetStore, snackbarStore };
  },
  data() {
    return {
      sheet: false,
      newFolderDialog: false,
      newFolderDialogLoading: false,
    };
  },
  methods: {
    async createNewFolder(folderName) {
      this.newFolderDialogLoading = true;
      const parentAssetId = this.assetStore.parentAssetId;

      const folder = {
        type: "folder",
        name: folderName,
        temporaryParentAssetId: parentAssetId,
      };

      const resp = await this.assetStore.createNewAssets(
        [folder],
        parentAssetId
      );
      if (resp) this.snackbarStore.text = this.$t("folderCreatedSuccessfully");
      else this.snackbarStore.text = this.$t("somethingWentWrong");
      this.newFolderDialogLoading = false;
      this.newFolderDialog = false;
      this.snackbarStore.snackbar = true;
    },
  },
};
</script>

<style scoped>
.theme--light .fab-wrapper {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.25) 10%,
    rgba(255, 255, 255, 0.5) 25%,
    rgba(255, 255, 255, 0.75) 45%,
    rgba(255, 255, 255, 1) 80%
  );
}

.theme--dark .fab-wrapper {
  background: linear-gradient(
    to bottom,
    rgba(18, 18, 18, 0),
    rgba(18, 18, 18, 0.25) 10%,
    rgba(18, 18, 18, 0.5) 25%,
    rgba(18, 18, 18, 0.75) 45%,
    rgba(18, 18, 18, 1) 80%
  );
}

.fab-wrapper {
  position: fixed;
  padding: 16px;
  bottom: 56px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.btn-wrapper {
  width: 33%;
}
</style>