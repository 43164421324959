<template>
  <v-app>
    <SideNavigation v-if="this.$vuetify.breakpoint.mdAndUp" />

    <TopNavigation
      v-if="$route.name === 'asset'"
      :assetLoading="assetLoading"
      :showSelectActions="showSelectActions"
    />

    <v-main>
      <v-container fluid class="px-5 py-4 pt-0">
        <router-view
          :save="save"
          :showSelectActions="showSelectActions"
          @change-asset-loading="assetLoading = $event"
          @change-asset-name="assetName = $event"
          @change-save-button-loading="loadingSaveButton = $event"
          @change-show-select-actions="showSelectActions = $event"
        ></router-view>
      </v-container>
    </v-main>

    <BottomNavigation
      v-if="
        this.$vuetify.breakpoint.smAndDown &&
        !showSelectActions &&
        accountStore.isLoggedIn
      "
    />

    <NotLoggedInMobileFab
      v-else-if="
        this.$vuetify.breakpoint.mdAndDown &&
        !showSelectActions &&
        !accountStore.isLoggedIn
      "
    />

    <CreateNewMobile v-if="showCreateNewMobile" />

    <FileUploadManager />

    <v-snackbar
      v-model="snackbarStore.snackbar"
      :timeout="snackbarStore.timeout"
      bottom
      transition="slide-y-reverse-transition"
    >
      {{ snackbarStore.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          rounded
          v-bind="attrs"
          @click="snackbarStore.snackbar = false"
        >
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";
import { useSnackbarStore } from "@/stores/snackbar";
import TopNavigation from "@/components/navigation/TopNavigation.vue";
import SideNavigation from "@/components/navigation/SideNavigation.vue";
import BottomNavigation from "@/components/navigation/BottomNavigation.vue";
import NotLoggedInMobileFab from "@/components/navigation/NotLoggedInMobileFab.vue";
import CreateNewMobile from "@/components/assets/CreateNewMobile.vue";
import FileUploadManager from "@/components/files/FileUploadManager.vue";

export default {
  components: {
    TopNavigation,
    SideNavigation,
    BottomNavigation,
    NotLoggedInMobileFab,
    CreateNewMobile,
    FileUploadManager,
  },
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, assetStore, snackbarStore };
  },
  created() {
    if (localStorage.locale && ["en", "sk"].includes(localStorage.locale))
      this.$i18n.locale = localStorage.locale;
    if (localStorage.theme && ["light", "dark"].includes(localStorage.theme))
      this.$vuetify.theme.dark = localStorage.theme === "dark";
  },
  data() {
    return {
      showSelectActions: false,
      showSearch: false,
      loadingSaveButton: false,
      loadingRemoveButton: false,
      save: false,
      assetLoading: false,
      assetName: null,
      assetActiveTab: 0,
      assetManageAccessDialog: false,
    };
  },
  computed: {
    showCreateNewMobile: function () {
      return (
        this.$route.name === "asset" &&
        this.$vuetify.breakpoint.smAndDown &&
        !this.showSelectActions &&
        (this.assetStore.parentAssetId
          ? this.assetStore.parentAsset.capabilities.createAsset
          : this.accountStore.checkAccountPermissions(
              "createAsset",
              this.assetStore.path
            ))
      );
    },
  },
  watch: {
    "assetStore.selected"() {
      if (this.showSelectActions && this.assetStore.selected.length === 0)
        this.showSelectActions = false;
    },
  },
};
</script>