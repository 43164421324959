import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import sk from 'vuetify/lib/locale/sk';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      // light: {
      //   primary: '#0360ec', // #E53935
      //   secondary: '#100F0F', // #E53935
      //   success: '#13e46d', // #FFCDD2
      //   error: '#fc010e', // #3F51B5
      // },
      light: {
        primary: "#63C894",
        secondary: '#100F0F',
      },
      dark: {
        primary: "#63C894",
        secondary: '#100F0F',
      },
    },
  },
  lang: {
    locales: { sk },
    current: 'sk',
  },
});
