<template>
  <v-dialog
    :value="qrScanning"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <v-sheet height="100%">
      <qrcode-stream v-if="qrScanning" @decode="onQrDecode" @init="onInit">
        <div v-if="qrScanningLoading" class="qr-scanning-loadingd-wrapper">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <v-btn
          v-if="qrScanning"
          fab
          depressed
          fixed
          bottom
          right
          color="primary"
          @click="$emit('change-qr-scanning', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </qrcode-stream>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "QrScanner",
  props: ["qrScanning"],
  components: {
    QrcodeStream,
  },
  data() {
    return {
      qrScanningLoading: false,
    };
  },
  methods: {
    createNewFolder(folderName) {
      const path = this.store.path;
      let folderPath;
      if (!path) folderPath = folderName;
      else folderPath = `${path}/${folderName}`;
      this.store.createNewFolder(folderPath);
      this.newFolderDialog = false;
    },
    onQrDecode(result) {
      this.$emit("qr-decode", result);
    },
    async onInit(promise) {
      this.qrScanningLoading = true;
      try {
        await promise;
        // successfully initialized
      } catch (error) {
        if (error.name === "NotAllowedError") {
          // user denied camera access permisson
        } else if (error.name === "NotFoundError") {
          // no suitable camera device installed
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
        }
      } finally {
        this.qrScanningLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.qr-scanning-loadingd-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
