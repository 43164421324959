<template>
  <div class="overlay">
    <div v-if="!$vuetify.breakpoint.smAndDown" class="illustration"></div>
    <div class="form-wrapper">
      <h2 class="mb-2">{{ $t("logInTitle") }}</h2>
      <p class="mb-8">
        {{ $t("logInDescription") }}
      </p>
      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="email"
          :rules="[rules.required, rules.email]"
          :label="$t('email')"
          type="email"
          rounded
          dense
          filled
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="[rules.required, rules.min, rules.max, rules.password]"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show = !show"
          :type="show ? 'text' : 'password'"
          :label="$t('password')"
          rounded
          dense
          filled
        ></v-text-field>
        <v-btn
          v-on:click="login"
          color="primary"
          class="mb-3"
          depressed
          rounded
          :disabled="!valid || snackbar"
          :loading="loading"
          >{{ $t("logIn") }}</v-btn
        >
        <div class="d-flex">
          <router-link
            :class="$vuetify.breakpoint.xs ? 'caption' : 'caption ml-2'"
            style="text-decoration: none"
            to="/forgot-password"
            >{{ $t("forgotPassword") }}</router-link
          >
          <router-link
            class="caption ml-2"
            style="text-decoration: none"
            to="/register"
            >{{ $t("noAccountYet") }}</router-link
          >
          <v-spacer></v-spacer>
          <a
            v-show="$i18n.locale !== 'sk'"
            class="caption ml-2"
            @click="changeLocale('sk')"
            >Slovenčina</a
          >
          <a
            v-show="$i18n.locale !== 'en'"
            class="caption ml-2"
            @click="changeLocale('en')"
            >English</a
          >
        </div>
      </v-form>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="-1"
      bottom
      transition="slide-y-reverse-transition"
    >
      {{ snackbarText }}
      <template v-slot:action>
        <v-btn text small rounded @click="reload()" color="white">{{
          $t("tryAgain")
        }}</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";

export default {
  name: "LoginView",
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    return { accountStore, assetStore };
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t("requiredField"),
        min: (v) => v.length >= 8 || this.$t("minChars", { chars: 8 }),
        max: (v) => v.length <= 48 || this.$t("maxChars", { chars: 48 }),
        email: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
          this.$t("emailMustBeCorrect"),
        password: (v) =>
          /^(?=.*([A-Z]){1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,48}$/.test(v) ||
          this.$t("passwordRegex"),
      },
      valid: true,
      email: "",
      show: false,
      password: "",
      loading: false,
      snackbar: false,
      snackbarText: this.$t("wrongEmailOrPassword"),
    };
  },
  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang;
      localStorage.locale = this.$i18n.locale;
    },
    async login() {
      this.loading = true;
      const resp = await this.accountStore.login(this.email, this.password);
      if (resp) {
        this.loading = false;
        const invitationToken = sessionStorage.getItem("invitationToken");
        this.$router.push(invitationToken ? "/invitation" : "/");
      } else {
        this.loading = false;
        this.snackbar = true;
      }
    },
    reload() {
      this.email = "";
      this.password = "";
      this.snackbar = false;
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
}

.theme--light .overlay {
  background-color: #fff;
}

.theme--dark .overlay {
  background-color: #121212;
}

.illustration {
  background-color: black;
  height: 100%;
  min-width: 50%;
}

.form-wrapper {
  width: fill-available;
  height: auto;
  /* margin: 0 auto; */
  padding: 64px;
}

.form-wrapper .logo {
  margin: 0 auto;
}

@media (max-width: 600px) {
  .form-wrapper {
    padding: 32px;
  }
}

.form-wrapper :deep(.v-btn) {
  width: 100%;
}

@media only screen and (min-width: 1264px) {
  .illustration {
    min-width: 65%;
  }
}

@media only screen and (max-width: 599px) {
  .overlay {
    overflow-y: scroll;
    text-align: start;
    align-items: flex-start;
    /* padding-top: 32px; */
  }

  .overlay::-webkit-scrollbar {
    display: none;
  }

  .overlay {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .form-wrapper {
    width: 100%;
  }

  .form-wrapper .logo {
    margin: 0;
  }
}
</style>