var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.fileUploadManagerStore.uploadingStatus)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"wrapper"},[_c('v-card',{attrs:{"min-width":"300px"}},[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_vm._v(_vm._s(_vm.fileUploadManagerStore.uploadingStatus === "started"
          ? _vm.$t("uploadingStarted")
          : _vm.fileUploadManagerStore.uploadingStatus === "uploading"
          ? _vm.$t("uploadingXOutOfY", {
              x: _vm.fileUploadManagerStore.uploadedCount,
              y: _vm.fileUploadManagerStore.queueLength,
            })
          : _vm.$t("uploadComplete"))+"\n      "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('v-icon',[_vm._v(_vm._s(_vm.expanded ? "mdi-chevron-down" : "mdi-chevron-up"))])],1),_vm._v(" "),(_vm.fileUploadManagerStore.uploadingStatus === 'completed')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.fileUploadManagerStore.clearQueue()}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_vm._v(" "),(_vm.fileUploadManagerStore.uploadingStatus !== 'started' && _vm.expanded)?_c('v-card-text',{staticClass:"py-2"},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-virtual-scroll',{attrs:{"items":_vm.fileUploadManagerStore.queue,"height":_vm.virtualScrollHeight,"item-height":"40","bench":"5"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"size":"24","tile":""}},[(item.status === 'uploaded')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-progress-circular',{class:item.progress ? 'primary--text' : 'text--disabled',attrs:{"value":item.progress,"indeterminate":!item.progress,"size":"16","rotate":!item.progress ? null : -90}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.file.name))])],1)],1)]}}],null,false,3564135609)})],1)],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }