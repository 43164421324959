<template>
  <div>
    <v-treeview
      item-key="_id"
      :items="assets"
      :load-children="fetchChildAssets"
      :open.sync="open"
      :active="[assetStore.parentAssetId ? assetStore.parentAssetId : '']"
      dense
      activatable
      hoverable
      rounded
      loading-icon="mdi-loading"
      expand-icon="mdi-chevron-down"
      color="primary"
      @update:active="activeUpdate"
    >
      <template v-slot:append="{ item, active }">
        <v-icon
          v-if="!active && assetStore.path.includes(item._id)"
          color="primary"
        >
          mdi-circle-small
        </v-icon>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "FoldersNavigator",
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, assetStore, snackbarStore };
  },
  data() {
    return {
      initialPaths: [],
      selectedPathsNotActiveYet: [],
      active: [],
      open: [],
      assets: [],
      loaded: false,
    };
  },
  watch: {
    "assetStore.parentAssetId"() {
      if (!this.loaded && !this.accountStore.isLoggedIn) {
        const parentAssetId = this.assetStore.parentAssetId;
        const parentAsset = this.assetStore.parentAsset;

        this.assets = [
          {
            _id: parentAsset._id,
            path: parentAsset.path,
            name: parentAsset.name,
            children: [],
          },
        ];

        this.open = [parentAssetId];

        this.loaded = true;
      }
    },
  },
  created() {
    if (this.accountStore.isLoggedIn) {
      this.assets.push({
        _id: "",
        path: "",
        name: this.$t("assets"),
        children: [],
      });

      this.open = [""];
    }
  },
  // TODO: implement watcher for assetstore to update changes to childAssets (mainly) like new folder/file upload,...
  methods: {
    async fetchChildAssets(item) {
      let assets;
      if (item._id === "") {
        const resp = await this.assetStore.fetchFolders();
        assets = resp.data.assets;
      } else {
        const resp = await this.assetStore.fetchAssetsFolders(
          item._id === "" ? null : item._id
        );
        assets = resp.data.asset.childAssets;
      }
      item.children = assets.map((asset) => ({
        ...asset,
        children: [],
      }));
    },
    activeUpdate(event) {
      try {
        const assetId = event[0];
        if (this.$route.params.id === assetId || assetId === undefined || assetId === null) return;
        console.log(assetId)
        this.$router.push(assetId === "" ? "/" : assetId);
      } catch (error) {
        return;
      }
    },
  },
};
</script>

<style scoped>
</style>