<template>
  <div>
    <v-list
      :dense="dense === false ? false : true"
      :class="dense === false ? 'rounded-t-xl' : null"
    >
      <v-list-item>
        <v-list-item-icon v-if="assetStore.selected.length === 1">
          <v-icon>{{
            assetStore.selectedAsset.type === "folder"
              ? "mdi-folder"
              : getFileIcon(assetStore.selectedAsset.file.type)
          }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            assetStore.selected.length === 1
              ? assetStore.selectedAsset.name
              : $t("xAssets", { assets: assetStore.selected.length })
          }}</v-list-item-title>
          <!-- <v-list-item-subtitle></v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="!isOptionsListEmpty" class="my-2"></v-divider>
      <div v-if="assetStore.selected.length === 1">
        <v-list-item
          @click="generateAssetQrCode()"
          :disabled="qrCodeSvgLoading"
        >
          <v-list-item-icon>
            <v-progress-circular
              v-if="qrCodeSvgLoading"
              indeterminate
              size="24"
            ></v-progress-circular>
            <v-icon v-else>mdi-qrcode</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("displayQrCode") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="copyAssetLink()">
          <v-list-item-icon>
            <v-icon>mdi-link</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("copyLink") }}</v-list-item-title>
        </v-list-item>
        <v-divider
          inset
          v-if="
            selectedCapabilities.manageAssetAccess || accountStore.isLoggedIn
          "
          class="my-2"
        ></v-divider>
        <v-list-item
          v-if="selectedCapabilities.manageAssetAccess"
          @click="
            manageAccessDialogMounted = true;
            manageAccessDialog = true;
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("manageAccess") }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="accountStore.isLoggedIn"
          @click="
            notificationsDialogMounted = true;
            notificationsDialog = true;
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-bell</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("notifications") }}</v-list-item-title>
        </v-list-item>
        <v-divider
          inset
          v-if="
            selectedCapabilities.renameAsset ||
            selectedCapabilities.reassignAssetId ||
            selectedCapabilities.moveAsset ||
            selectedCapabilities.removeAsset
          "
          class="my-2"
        ></v-divider>
        <v-list-item
          v-if="selectedCapabilities.renameAsset"
          :disabled="renameLoading"
          @click="renameAsset()"
        >
          <v-list-item-icon>
            <v-progress-circular
              v-if="renameLoading"
              indeterminate
              size="24"
            ></v-progress-circular>
            <v-icon v-else>mdi-rename-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("rename") }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="selectedCapabilities.reassignAssetId"
          @click="qrScanning = true"
        >
          <v-list-item-icon>
            <v-icon>mdi-qrcode-scan</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("assignToNewQrCode") }}</v-list-item-title>
        </v-list-item>
      </div>
      <v-list-item
        v-if="selectedCapabilities.moveAsset"
        @click="
          moveAssetDialogMounted = true;
          moveAssetDialog = true;
        "
      >
        <v-list-item-icon>
          <v-icon>mdi-folder-move</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("move") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedCapabilities.removeAsset"
        :disabled="removeLoading"
        @click="removeSelected"
      >
        <v-list-item-icon>
          <v-progress-circular
            v-if="removeLoading"
            indeterminate
            size="24"
          ></v-progress-circular>
          <v-icon v-else>mdi-delete-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("remove") }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <ConfirmDialog ref="confirm" />
    <v-dialog v-model="qrCodeDialog" max-width="500">
      <v-img :src="qrCodeSvg"></v-img>
    </v-dialog>
    <ManageAccess
      v-if="manageAccessDialogMounted"
      :key="`ma-${assetStore.selectedAssetId}`"
      :dialog="manageAccessDialog"
      :assetId="assetStore.selectedAssetId"
      @close-dialog="
        manageAccessDialog = false;
        $emit('close-dialog');
      "
    />
    <Notifications
      v-if="notificationsDialogMounted"
      :key="`n-${assetStore.selectedAssetId}`"
      :dialog="notificationsDialog"
      :assetId="assetStore.selectedAssetId"
      @close-dialog="
        notificationsDialog = false;
        $emit('close-dialog');
      "
    />
    <Move
      v-if="moveAssetDialogMounted"
      :dialog="moveAssetDialog"
      @close-dialog="moveAssetDialog = false"
    />
    <QrScanner
      :qrScanning="qrScanning"
      @change-qr-scanning="qrScanning = $event"
      @qr-decode="handleDecodedQr"
    />
    <PromptDialog ref="prompt" />
  </div>
</template>

<script>
import { useAssetStore } from "@/stores/asset";
import { useAccountStore } from "@/stores/account";
import { useSnackbarStore } from "@/stores/snackbar";
import { generateQrCode, getFileIcon } from "@/util/helpers";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import PromptDialog from "@/components/dialogs/PromptDialog.vue";
import ManageAccess from "@/components/assets/options/access/ManageAccess.vue";
import Move from "@/components/assets/options/Move.vue";
import Notifications from "@/components/assets/options/notifications/Notifications.vue";
import QrScanner from "@/components/QrScanner.vue";

export default {
  name: "AssetOptionsList",
  props: ["dense"],
  components: {
    ConfirmDialog,
    PromptDialog,
    ManageAccess,
    Notifications,
    QrScanner,
    Move,
  },
  setup() {
    const assetStore = useAssetStore();
    const accountStore = useAccountStore();
    const snackbarStore = useSnackbarStore();
    return { assetStore, accountStore, snackbarStore };
  },
  data() {
    return {
      qrCodeDialog: false,
      qrCodeSvgLoading: false,
      qrCodeSvg: null,
      manageAccessKey: null,
      manageAccessDialogMounted: false,
      manageAccessDialog: false,
      notificationsKey: null,
      notificationsDialogMounted: false,
      notificationsDialog: false,
      moveAssetDialogMounted: false,
      moveAssetDialog: false,
      renameLoading: false,
      removeLoading: false,
      qrScanning: false,
    };
  },
  computed: {
    isOptionsListEmpty() {
      if (
        this.selectedCapabilities.moveAsset ||
        this.selectedCapabilities.removeAsset
      )
        return false;
      if (this.assetStore.selected.length === 1) return false;
      return true;
    },
    selectedCapabilities() {
      try {
        if (this.assetStore.selectedAssetId)
          return this.assetStore.selectedAsset.capabilities;

        const selectedAssets = this.assetStore.childAssets.filter(
          (item) => this.assetStore.selected.indexOf(item._id) > -1
        );

        return {
          readAsset: selectedAssets.every((e) => e.capabilities.readAsset),
          moveAsset: selectedAssets.every((e) => e.capabilities.moveAsset),
          removeAsset: selectedAssets.every((e) => e.capabilities.removeAsset),
        };
      } catch (error) {
        return {};
      }
    },
  },
  watch: {
    "assetStore.selectedAssetId"() {
      this.manageAccessDialogMounted = false;
      this.notificationsDialogMounted = false;
    },
  },
  methods: {
    getFileIcon(fileType) {
      return getFileIcon(fileType)
    },
    async generateAssetQrCode() {
      this.qrCodeSvgLoading = true;
      const assetId = this.assetStore.selectedAssetId;
      const resp = await this.assetStore.fetchAssetOwnerAccount(assetId);
      const config = resp.data.account.qrGeneratorConfig;
      const aoLogo = require("@/assets/logo-black.svg");
      const imageUrl = config.image
        ? config.image.source
          ? config.image.source
          : aoLogo
        : aoLogo;

      if (imageUrl) {
        const imageResp = await fetch(imageUrl);
        const imageBlob = await imageResp.blob();
        const imageDataUrl = await new Promise((r) => {
          let a = new FileReader();
          a.onload = r;
          a.readAsDataURL(imageBlob);
        }).then((e) => e.target.result);
        config.image = imageDataUrl;
      } else config.image = null;

      const urlBase = window.location.origin;
      const link = `${urlBase}/${assetId}`;

      const qrCode = generateQrCode(link, config);

      let URL = window.URL || window.webkitURL || window;
      this.qrCodeSvg = URL.createObjectURL(await qrCode.getRawData("svg"));


      this.qrCodeSvgLoading = false;
      this.$emit("close-dialog");
      this.qrCodeDialog = true;
    },
    copyAssetLink() {
      try {
        const assetId = this.assetStore.selectedAssetId;
        const urlBase = window.location.origin;
        const url = `${urlBase}/${assetId}`;
        navigator.clipboard.writeText(url);
        this.$emit("close-dialog");
        this.snackbarStore.text = this.$t("linkCopiedSuccessfully");
        this.snackbarStore.snackbar = true;
      } catch (error) {
        console.log(error);
      }
      this.sheet = false;
    },
    async renameAsset() {
      const newName = await this.$refs.prompt.open(
        this.$t("renameAsset"),
        this.$t("assetName"),
        this.assetStore.selectedAsset.name,
        "assetName"
      );

      if (newName === false) return;

      this.renameLoading = true;
      const resp = await this.assetStore.renameAsset(
        this.assetStore.selectedAssetId,
        newName
      );
      if (resp) {
        this.snackbarStore.text = this.$t("assetRenamedSuccessfully");
        this.$emit("close-dialog");
      } else this.snackbarStore.text = this.$t("somethingWentWrong");
      this.snackbarStore.snackbar = true;
      this.renameLoading = false;
    },
    async handleDecodedQr(result) {
      try {
        this.qrScanning = false;
        const newAssetId = result.match(/\w+$/)[0];
        if (
          await this.$refs.confirm.open(
            this.$t("pleaseConfirm"),
            this.$t("areYouSureYouWantToAssignAssetToNewQrCode")
          )
        ) {
          this.reassigningLoading = true;
          const resp = await this.assetStore.reassignAssetId(
            this.assetStore.selectedAssetId,
            newAssetId
          );
          if (resp) {
            this.$emit("close-dialog");
            this.snackbarStore.text = this.$t(
              "assetAssignedToNewQrCodeSuccessfully"
            );
            if (this.$route.params.id === this.assetStore.selectedAssetId)
              this.$router.push(`/${newAssetId}`);
          } else this.snackbarStore.text = this.$t("somethingWentWrong");
          this.snackbarStore.snackbar = true;
          this.removeLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async removeSelected() {
      try {
        if (
          await this.$refs.confirm.open(
            this.$t("pleaseConfirm"),
            this.$t("areYouSureYouWantToRemoveSelectedAssets")
          )
        ) {
          this.removeLoading = true;
          const resp = await this.assetStore.removeSelected();
          if (resp) {
            this.snackbarStore.text = this.$t("assetRemovedSuccessfully");
            this.$emit("close-dialog");
          } else this.snackbarStore.text = this.$t("somethingWentWrong");
          this.snackbarStore.snackbar = true;
          this.removeLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>