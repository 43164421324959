<template>
  <v-dialog :value="dialog" persistent max-width="350">
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("newFolder") }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="folderName"
          :label="`${$t('folderName')} *`"
          :placeholder="$t('folderName')"
          rounded
          filled
          hide-details
          single-line
          dense
          autofocus
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text rounded @click="$emit('close-dialog')">
          {{ $t("close") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          rounded
          :disabled="!folderName && !loading"
          :loading="loading"
          @click="createNewFolder()"
          >{{ $t("create") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewFolderDialog",
  props: ["dialog", "loading"],
  data() {
    return {
      folderName: "",
    };
  },
  methods: {
    createNewFolder() {
      this.$emit("create-new-folder", this.folderName);
      this.folderName = "";
    },
  },
};
</script>
