<template>
  <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="transparent" flat tile>
          <v-btn
            icon
            @click="
              screen === 'access' ? $emit('close-dialog') : (screen = 'access')
            "
          >
            <v-icon>{{
              screen === "access" ? "mdi-close" : "mdi-arrow-left"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              screen === "access"
                ? $t("manageAccess")
                : screen === "newAccountAccess"
                ? $t("addAccount")
                : $t("editAccountPermissions")
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="screen === 'access'"
            icon
            @click="screen = 'newAccountAccess'"
            :disabled="loading || asset.isAccessInherited"
          >
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
          <v-btn
            v-if="screen === 'newAccountAccess'"
            icon
            :loading="addAccountAccessLoading"
            :disabled="!valid"
            @click="addAccountAccess()"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn
            v-if="
              screen === 'accountAccess' &&
              accountAccess.permissions.length === 0
            "
            icon
            :loading="removeAccountAccessLoading"
            @click="removeAccountAccess()"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="screen === 'accountAccess'"
            icon
            :loading="updateAccountAccessLoading"
            @click="updateAccountAccess()"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <div v-show="screen === 'access'">
          <v-skeleton-loader
            :loading="loading"
            type="list-item-avatar-two-line, list-item-two-line"
            class="pa-0"
          >
            <template v-slot:default>
              <AssetAccountList
                :asset="asset"
                @view-account-access="viewAccountAccess"
                class="mt-2 mb-4"
              />
              <v-divider></v-divider>
              <v-switch
                v-model="asset.access.isPublic"
                inset
                :label="
                  asset.access.isPublic
                    ? $t('assetPubliclyViewable')
                    : $t('assetViewabilityRestricted')
                "
                :hint="
                  asset.access.isPublic
                    ? $t('assetPubliclyViewableHint')
                    : $t('assetViewabilityRestrictedHint')
                "
                persistent-hint
                class="mt-4"
                @change="setIsPublic(asset.access.isPublic)"
                :disabled="isPublicLoading || asset.isAccessInherited"
                :loading="isPublicLoading ? 'white' : false"
              ></v-switch>

              <div v-if="asset.isAccessInherited" class="mt-4">
                <span v-if="setCustomAccessLoading" class="primary--text">
                  <v-progress-circular
                    indeterminate
                    size="19"
                    color="primary"
                    class="mr-2"
                  ></v-progress-circular>
                  {{ $t("settingCustomAccess") }}
                </span>
                <span v-else>
                  {{ $t("assetAccessIsInherited") }}
                  <a @click="setCustomAccess()">{{ $t("setCustomAccess").toLowerCase() }}</a>
                </span>
              </div>
              <div
                v-if="!asset.isAccessInherited && asset.hasOwnAccess"
                class="mt-4"
              >
                <span v-if="resetAccessLoading" class="primary--text">
                  <v-progress-circular
                    indeterminate
                    size="19"
                    color="primary"
                    class="mr-2"
                  ></v-progress-circular>
                  {{ $t("resetingAssetAccess") }}
                </span>
                <span v-else>
                  {{ $t("assetHasCustomAccess") }}
                  <a @click="resetAccess()">{{ $t("resetAccess").toLowerCase() }}</a>
                </span>
              </div>
            </template>
          </v-skeleton-loader>
        </div>
        <div v-if="screen === 'newAccountAccess'">
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="accountAccessEmail"
              :rules="[rules.required, rules.email]"
              :label="$t('email')"
              type="email"
              rounded
              filled
              hide-details
              class="mb-2"
            ></v-text-field>
            <v-chip-group
              v-model="accountAccessPermissions"
              active-class="primary--text"
              column
              multiple
            >
              <v-chip filter value="createAsset">{{
                $t("createAsset")
              }}</v-chip>
              <v-chip filter value="readAsset">{{ $t("readAsset") }}</v-chip>
              <v-chip filter value="renameAsset">{{
                $t("renameAsset")
              }}</v-chip>
              <!-- <v-chip filter value="moveAsset">{{ $t("moveAsset") }}</v-chip> -->
              <v-chip filter value="manageAssetAccess">{{
                $t("manageAssetAccess")
              }}</v-chip>
              <!-- <v-chip filter value="reassignAssetId">{{
                $t("reassignAssetId")
              }}</v-chip> -->
              <v-chip filter value="removeAsset">{{
                $t("removeAsset")
              }}</v-chip>
            </v-chip-group>
          </v-form>
        </div>
        <div v-if="screen === 'accountAccess'">
          <v-text-field
            v-model="accountAccess.info.name"
            :label="$t('name')"
            rounded
            filled
            hide-details
            readonly
            class="mb-2"
          ></v-text-field>
          <v-text-field
            v-model="accountAccess.email"
            :label="$t('email')"
            type="email"
            rounded
            filled
            hide-details
            readonly
            class="mb-2"
          ></v-text-field>
          <v-chip-group
            v-model="accountAccess.permissions"
            active-class="primary--text"
            column
            multiple
          >
            <v-chip filter value="createAsset">{{ $t("createAsset") }}</v-chip>
            <v-chip filter value="readAsset">{{ $t("readAsset") }}</v-chip>
            <v-chip filter value="renameAsset">{{ $t("renameAsset") }}</v-chip>
            <!-- <v-chip filter value="moveAsset">{{ $t("moveAsset") }}</v-chip> -->
            <v-chip filter value="manageAssetAccess">{{
              $t("manageAssetAccess")
            }}</v-chip>
            <!-- <v-chip filter value="reassignAssetId">{{
              $t("reassignAssetId")
            }}</v-chip> -->
            <v-chip filter value="removeAsset">{{ $t("removeAsset") }}</v-chip>
          </v-chip-group>
        </div>
      </v-card-text>
    </v-card>
    <ConfirmDialog ref="confirm" />
  </v-dialog>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";
import { useSnackbarStore } from "@/stores/snackbar";

import { deepCopy } from "@/util/helpers";

import AssetAccountList from "@/components/assets/options/access/AssetAccountList.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

export default {
  name: "ManageAccess",
  props: ["dialog"],
  components: {
    AssetAccountList,
    ConfirmDialog,
  },
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, assetStore, snackbarStore };
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t("requiredField"),
        email: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
          this.$t("emailMustBeCorrect"),
      },
      valid: true,
      screen: "access",
      accountAccessEmail: "",
      accountAccessPermissions: ["readAsset"],
      addAccountAccessLoading: false,
      removeAccountAccessLoading: false,
      updateAccountAccessLoading: false,
      accountAccess: null,
      asset: null,
      loading: false,
      isPublicLoading: false,
      resetAccessLoading: false,
      setCustomAccessLoading: false,
    };
  },
  async created() {
    await this.sync();
  },
  methods: {
    viewAccountAccess(accountAccessId) {
      this.accountAccess = deepCopy(
        this.asset.access.accounts.find((e) => e._id === accountAccessId)
      );
      this.screen = "accountAccess";
    },
    async sync() {
      this.loading = true;
      const assetId = this.assetStore.selected[0];
      const resp = await this.assetStore.fetchAssetAccess(assetId);
      this.asset = resp.data.asset;
      this.loading = false;
    },
    async setIsPublic(isPublic) {
      try {
        this.isPublicLoading = true;
        const assetId = this.asset._id;
        const resp = await this.assetStore.setAssetIsPublic(assetId, isPublic);
        this.isPublicLoading = false;
        if (resp) {
          this.asset.hasOwnAccess = true;
          this.asset.isAccessInherited = false;
          this.snackbarStore.text = this.$t("assetAccessUpdatedSuccessfully");
        } else this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
    async setCustomAccess() {
      try {
        this.setCustomAccessLoading = true;
        const assetId = this.asset._id;
        const resp = await this.assetStore.setAssetIsPublic(assetId, false);
        this.setCustomAccessLoading = false;
        if (resp) {
          this.asset.access = { isPublic: false, accounts: [] };
          this.asset.hasOwnAccess = true;
          this.asset.isAccessInherited = false;
          this.snackbarStore.text = this.$t("assetAccessUpdatedSuccessfully");
        } else this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
    async resetAccess() {
      try {
        if (
          await this.$refs.confirm.open(
            this.$t("pleaseConfirm"),
            this.$t("areYouSureYouWantToResetAccessToThisAsset")
          )
        ) {
          this.resetAccessLoading = true;
          const assetId = this.asset._id;
          const resp = await this.assetStore.resetAssetAccess(assetId);
          const resp2 = await this.assetStore.fetchAssetAccess(assetId);
          this.resetAccessLoading = false;
          if (resp && resp2) {
            this.asset = resp2.data.asset;
            this.snackbarStore.text = this.$t("assetAccessResetSuccessfully");
          } else this.snackbarStore.text = this.$t("somethingWentWrong");
          this.snackbarStore.snackbar = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async addAccountAccess() {
      try {
        this.addAccountAccessLoading = true;
        const assetId = this.asset._id;
        const accountAccess = {
          accountEmail: this.accountAccessEmail,
          permissions: this.accountAccessPermissions,
        };
        const resp = await this.assetStore.addAccountAccess(
          assetId,
          accountAccess
        );

        this.addAccountAccessLoading = false;
        if (resp) {
          this.asset.access.accounts.push(resp.data.account);
          this.asset.hasOwnAccess = true;
          this.accountAccessEmail = "";
          this.accountAccessPermissions = ["readAsset"];
          this.snackbarStore.text = this.$t("accountAddedSuccessfully");
          this.screen = "access";
        } else this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
    async removeAccountAccess() {
      try {
        if (
          await this.$refs.confirm.open(
            this.$t("pleaseConfirm"),
            this.$t("areYouSureYouWantToRemoveThisAccount")
          )
        ) {
          this.removeAccountAccessLoading = true;
          const assetId = this.asset._id;
          const accountAccessId = this.accountAccess._id;
          const resp = await this.assetStore.removeAccountAccess(
            assetId,
            accountAccessId
          );
          this.removeAccountAccessLoading = false;
          if (resp) {
            const accountAccessIndex = this.asset.access.accounts.findIndex(
              (e) => e._id === accountAccessId
            );
            if (accountAccessIndex > -1)
              this.asset.access.accounts.splice(accountAccessIndex, 1);
            this.snackbarStore.text = this.$t("accountRemovedSuccessfully");
            this.screen = "access";
          } else this.snackbarStore.text = this.$t("somethingWentWrong");
          this.snackbarStore.snackbar = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateAccountAccess() {
      try {
        this.updateAccountAccessLoading = true;
        const assetId = this.asset._id;
        const accountAccessId = this.accountAccess._id;
        const permissions = deepCopy(this.accountAccess.permissions);
        const resp = await this.assetStore.updateAccountAccess(
          assetId,
          accountAccessId,
          permissions
        );
        this.updateAccountAccessLoading = false;
        if (resp) {
          const accountAccessIndex = this.asset.access.accounts.findIndex(
            (e) => e._id === accountAccessId
          );
          if (accountAccessIndex > -1)
            this.asset.access.accounts[accountAccessIndex].permissions =
              permissions;
          this.snackbarStore.text = this.$t("permissionsUpdatedSuccessfully");
          this.screen = "access";
        } else this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
:deep(.v-skeleton-loader__list-item-avatar-two-line),
:deep(.v-skeleton-loader__list-item-two-line) {
  padding: 0;
}
</style>