import { defineStore } from 'pinia'
import axios from "axios"
import localforage from 'localforage'
import { API_URL } from '../api.js'
import { pick } from "../util/helpers";
import { useAccountStore } from "@/stores/account";

export const useAssetStore = defineStore("asset", {
    state: () => ({
        path: "",
        parentAsset: null,
        childAssets: [],
        previewedFileAsset: null,
        cachedAssetNames: [],
        selected: [],
        lastSelected: null,
        refresh: false,
    }),
    getters: {
        parentAssetId: state => { try { return state.parentAsset._id } catch (error) { return null } },
        selectedAssetId: state => { if (state.selected.length === 1) return state.selected[0]; return null; },
        selectedAsset: state => {
            try {
                if (state.selectedAssetId === state.parentAssetId) return state.parentAsset;
                if (state.previewedFileAsset && state.selectedAssetId === state.previewedFileAsset._id)
                    return state.previewedFileAsset; return state.childAssets.find(asset => asset._id === state.selectedAssetId)
            } catch (error) { return null }
        },
    },
    actions: {
        async persist() {
            await localforage.setItem("assetStore", this.$state)
        },
        async generateAssetIds(amount) {
            try {
                const resp = await axios.post(`${API_URL}/assets/generate-ids`, { amount }, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async fetchUnusedAssetIds() {
            try {
                const resp = await axios.get(`${API_URL}/assets/unused-ids`, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async createNewAssets(assets, parentAssetId = null) {
            try {
                const resp = await axios.post(`${API_URL}/assets`, { assets, parentAssetId }, { withCredentials: true })
                const data = resp.data

                data.assets.forEach(asset => {
                    this.cachedAssetNames.push(pick(asset, ["_id", "name", "type"]))
                    if (this.parentAssetId === asset.parentAssetId) this.childAssets.push(asset)
                })

                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async fetchAssets() {
            try {
                const resp = await axios.get(`${API_URL}/assets`, { withCredentials: true })
                const data = resp.data;

                this.parentAsset = null
                this.childAssets = data.assets
                this.path = ""
                this.selected = []

                data.assets.forEach(asset => {
                    const cachedAssetNamesIndex = this.cachedAssetNames.findIndex(e => e._id === asset._id)
                    if (cachedAssetNamesIndex === -1) this.cachedAssetNames.push(pick(asset, ["_id", "name", "type"]))
                    else this.cachedAssetNames[cachedAssetNamesIndex] = pick(asset, ["_id", "name", "type"])
                });

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchFolders() {
            try {
                const resp = await axios.get(`${API_URL}/assets?foldersOnly=true`, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async queryAssets(query) {
            try {
                const resp = await axios.get(`${API_URL}/assets?query=${query}`, { withCredentials: true })
                const data = resp.data;

                data.assets.forEach(asset => {
                    const cachedAssetNamesIndex = this.cachedAssetNames.findIndex(e => e._id === asset._id)
                    if (cachedAssetNamesIndex === -1) this.cachedAssetNames.push(pick(asset, ["_id", "name", "type"]))
                    else this.cachedAssetNames[cachedAssetNamesIndex] = pick(asset, ["_id", "name", "type"])
                });

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchAsset(assetId) {
            // const accountStore = useAccountStore()
            try {
                // const assetIndex = this.assets.findIndex(e => e._id === assetId)
                const resp = await axios.get(`${API_URL}/assets/${assetId}`, { withCredentials: true })
                const data = resp.data;
                const asset = data.asset

                const updateCachedAssetName = (asset) => {
                    const cachedAssetNamesIndex = this.cachedAssetNames.findIndex(e => e._id === asset._id)
                    if (cachedAssetNamesIndex === -1) this.cachedAssetNames.push(pick(asset, ["_id", "name", "type"]))
                    else this.cachedAssetNames[cachedAssetNamesIndex] = pick(asset, ["_id", "name", "type"])
                }

                updateCachedAssetName(asset)

                if (asset.type === "folder") {
                    this.parentAsset = asset
                    this.childAssets = asset.childAssets
                    this.path = asset.path ? `${asset.path}/${asset._id}` : asset._id;
                    this.selected = []

                    asset.childAssets.forEach(asset => {
                        updateCachedAssetName(asset)
                    });
                } else if (asset.type === "file") this.previewedFileAsset = asset


                // const asset = pick(data.asset, ["_id", "type", "name", "parentAssetId"])
                // if (accountStore.isLoggedIn && data.asset.ownerAccountId === (accountStore.account.subAccountOf || accountStore.account._id)) {
                //     if (assetIndex === -1) this.assets.push(asset)
                //     else this.assets[assetIndex] = asset
                //     await this.persist()
                // }

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchAssetsFolders(assetId) {
            try {
                const resp = await axios.get(`${API_URL}/assets/${assetId}?foldersOnly=true`, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchAssetOwnerAccount(assetId) {
            try {
                const resp = await axios.get(`${API_URL}/assets/${assetId}/owner-account`, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchAssetAccess(assetId) {
            try {
                const resp = await axios.get(`${API_URL}/assets/${assetId}/access`, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async renameAsset(assetId, name) {
            try {
                const resp = await axios.patch(`${API_URL}/assets/${assetId}`, { name }, { withCredentials: true })
                const newName = resp.data.asset.name

                const cachedAssetNamesIndex = this.cachedAssetNames.findIndex(e => e._id === assetId)
                // TODO: push type
                if (cachedAssetNamesIndex === -1) this.cachedAssetNames.push({ _id: assetId, name })
                else this.cachedAssetNames[cachedAssetNamesIndex].name = newName

                if (this.parentAssetId === assetId) this.parentAsset.name = newName

                const childAssetIndex = this.childAssets.findIndex(e => e._id === assetId)
                if (childAssetIndex > -1) {
                    this.childAssets[childAssetIndex].name = newName
                    if (this.childAssets[childAssetIndex].type === "file") this.childAssets[childAssetIndex].file.name = newName
                }

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async moveAsset(assetId, parentAssetId) {
            try {
                const resp = await axios.patch(`${API_URL}/assets/${assetId}`, { parentAssetId }, { withCredentials: true })

                const childAssetIndex = this.childAssets.findIndex(e => e._id === assetId)

                if (childAssetIndex > -1 && this.parentAssetId !== parentAssetId) this.childAssets.splice(childAssetIndex, 1)

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async moveSelected(parentAssetId) {
            try {
                const ids = this.selected
                const resp = await axios.patch(`${API_URL}/assets/move`, { ids, parentAssetId }, { withCredentials: true })

                this.childAssets = this.childAssets.filter(asset => !ids.includes(asset._id))
                this.selected = []

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async resetAssetAccess(assetId) {
            try {
                const resp = await axios.delete(`${API_URL}/assets/${assetId}/access`, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async setAssetIsPublic(assetId, isPublic) {
            try {
                const resp = await axios.patch(`${API_URL}/assets/${assetId}/access`, { isPublic }, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async reassignAssetId(assetId, newAssetId) {
            try {
                const resp = await axios.patch(`${API_URL}/assets/${assetId}/reassign-id`, { newAssetId }, { withCredentials: true })

                // TODO: if (this.parentAssetId === assetId) router.push(/assetId)
                const childAssetIndex = this.childAssets.findIndex(e => e._id === assetId)
                if (childAssetIndex > -1) this.childAssets[childAssetIndex]._id = newAssetId

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async removeAsset(assetId) {
            try {
                const resp = await axios.delete(`${API_URL}/assets/${assetId}`, { withCredentials: true })

                const childAssetIndex = this.childAssets.findIndex(e => e._id === assetId)
                if (childAssetIndex > -1) this.assets.splice(childAssetIndex, 1)

                const cachedAssetNamesIndex = this.cachedAssetNames.findIndex(e => e._id === assetId)
                if (cachedAssetNamesIndex > -1) this.assets.splice(cachedAssetNamesIndex, 1)

                // TODO: if assetId === this.parentAssetId => navigate to parentAssetId of this.parentAsset

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async removeSelected() {
            try {
                const ids = this.selected.join(",")
                const resp = await axios.delete(`${API_URL}/assets?ids=${ids}`, { withCredentials: true })

                this.cachedAssetNames = this.cachedAssetNames.filter(asset => !ids.includes(asset._id))
                this.childAssets = this.childAssets.filter(asset => !ids.includes(asset._id))
                this.selected = []

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        // checkAssetAccess(permission, newPath = null) {
        //     const accountStore = useAccountStore()
        //     try {
        //         const asset = this.currentAsset;
        //         const account = accountStore.account
        //         if (permission === "readAsset" && asset.access && asset.access.isPublic) return true
        //         if (permission === "readAsset" && asset.access && !asset.access.isPublic && !accountStore.isLoggedIn) return false

        //         const ownerAccountId = asset.ownerAccountId.toString()
        //         const accountId = account._id.toString()

        //         if (ownerAccountId === accountId) return true
        //         if (ownerAccountId === account.subAccountOf.toString() && accountStore.checkAccountPermissions(permission, asset.path, newPath)) return true
        //         return asset.access && asset.access.accounts.some(e => e._id.toString() === accountId && e.permissions.includes(permission));
        //     } catch (error) {
        //         return false
        //     }
        // },
        async addAccountAccess(assetId, accountAccess) {
            try {
                const resp = await axios.post(`${API_URL}/assets/${assetId}/access/accounts`, accountAccess, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async updateAccountAccess(assetId, accountAccessId, permissions) {
            try {
                const resp = await axios.patch(`${API_URL}/assets/${assetId}/access/accounts/${accountAccessId}`, { permissions }, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async removeAccountAccess(assetId, accountAccessId) {
            try {
                const resp = await axios.delete(`${API_URL}/assets/${assetId}/access/accounts/${accountAccessId}`, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        isParentAsset3rdParty() {
            const accountStore = useAccountStore()
            if (!accountStore.isLoggedIn) return true
            return !!(this.parentAssetId && this.parentAsset.ownerAccountId !== (accountStore.account.subAccountOf || accountStore.account._id))
        }
    },
})