import Router from "vue-router";
import { useAccountStore } from "@/stores/account";
import Register from "@/views/auth/Register.vue";
import Login from "@/views/auth/Login.vue";
import Verification from "@/views/auth/Verification.vue";
import AssetFetchError from "@/views/AssetFetchError.vue";

const ifAuthenticated = (to, from, next) => {
  const store = useAccountStore()
  if (store.isLoggedIn) return next();
  return next("/login");
};

const ifAuthenticatedAndVerified = (to, from, next) => {
  const store = useAccountStore()
  if (store.isLoggedIn && store.isVerified) return next();
  if (!store.isLoggedIn) return next("/login");
  if (!store.isVerified) return next("/verification");
};

// const ifAccountTierBusiness = (to, from, next) => {
//   const store = useAccountStore()
//   if (!store.isLoggedIn) return next("/login");
//   if (!store.isVerified) return next("/verification");
//   if (store.accountTier === "business") return next();
//   return false;
// };

const routes = [
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import(/* webpackChunkName: "forgot-password" */ "@/views/auth/ForgotPassword.vue"),
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import(/* webpackChunkName: "change-password" */ "@/views/auth/ChangePassword.vue"),
  },
  {
    path: "/invitation",
    name: "invitation",
    component: () => import(/* webpackChunkName: "invitation" */ "@/views/Invitation.vue"),
  },
  {
    path: "/verification",
    name: "verification",
    component: Verification,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/account",
    name: "account",
    component: () => import(/* webpackChunkName: "account" */ "@/views/Account.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/team",
    name: "team",
    component: () => import(/* webpackChunkName: "team" */ "@/views/Team.vue"),
    beforeEnter: ifAuthenticatedAndVerified,
  },
  {
    path: "/qrcodes",
    name: "qrcodes",
    component: () => import(/* webpackChunkName: "qrcodes" */ "@/views/QrCodes.vue"),
    beforeEnter: ifAuthenticatedAndVerified,
  },
  {
    path: "/qrcodes-export",
    name: "qrcodes-export",
    component: () => import(/* webpackChunkName: "qrcodes-export" */ "@/views/AgencyQrCodesExport.vue"),
  },
  {
    path: "/asset-error",
    name: "asset-error",
    component: AssetFetchError,
  },
  {
    path: "/:id",
    name: "asset",
    component: () => import(/* webpackChunkName: "asset" */ "@/views/Asset.vue"),
  },
  {
    path: "*",
    redirect: { name: "asset" },
  }
]

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
