<template>
  <v-responsive :height="height" class="d-flex justify-center align-center">
    <div class="text-center">
      <div class="text-h5 mb-1">
        {{ $t("errorOccurredWhileGettingYourAsset") }}
      </div>
      <div class="body-2 text--secondary mb-4">
        {{ $t("requestedAssetMayNoLongerExist") }}
      </div>
      <v-btn
        small
        rounded
        depressed
        outlined
        color="primary"
        @click="tryAgain()"
      >
        <v-icon left>mdi-refresh</v-icon>
        {{ $t("tryAgain") }}</v-btn
      >
    </div>
  </v-responsive>
</template>

<script>
import { useAssetStore } from "@/stores/asset";

export default {
  name: "AssetFetchError",
  setup() {
    const assetStore = useAssetStore();
    return { assetStore };
  },
  computed: {
    height() {
      if (this.$vuetify.breakpoint.smAndDown)
        return this.$vuetify.breakpoint.height - 56;
      return this.$vuetify.breakpoint.height;
    },
  },
  methods: {
    tryAgain() {
      this.$router.push(
        this.$route.query.assetId ? this.$route.query.assetId : "/"
      );
    },
  },
};
</script>