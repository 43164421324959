<template>
  <div class="overlay">
    <div v-if="!$vuetify.breakpoint.smAndDown" class="illustration"></div>
    <div class="form-wrapper">
      <h2 class="mb-2">{{ $t("pleaseCheckYourEmail") }}</h2>
      <p class="mb-8">{{ $t("pleaseVerifyYourEmailDescription") }}</p>
      <v-btn
        color="primary"
        depressed
        rounded
        :loading="loading"
        @click="checkVerification()"
        >{{ $t("checkVerification") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "VerificationView",
  setup() {
    const accountStore = useAccountStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, snackbarStore };
  },
  data() {
    return { loading: false };
  },
  methods: {
    async checkVerification() {
      this.loading = true;
      await this.accountStore.fetchAccount();
      if (this.accountStore.isVerified) {
        const invitationToken = sessionStorage.getItem("invitationToken");
        return this.$router.push(invitationToken ? "/invitation" : "/");
      }
      this.loading = false;
      this.snackbarStore.text = this.$t("emailNotVerified");
      this.snackbarStore.snackbar = true;
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  /* align-items: center; */
  /* text-align: left; */
}

.theme--light .overlay {
  background-color: #fff;
}

.theme--dark .overlay {
  background-color: #121212;
}

.illustration {
  background-color: black;
  height: 100%;
  min-width: 50%;
}

.form-wrapper {
  width: fill-available;
  height: auto;
  /* margin: 0 auto; */
  padding: 64px;
}

.form-wrapper .logo {
  margin: 0 auto;
}

@media only screen and (min-width: 1264px) {
  .illustration {
    min-width: 65%;
  }
}

@media (max-width: 600px) {
  .form-wrapper {
    padding: 32px;
  }
}

.form-wrapper :deep(.v-btn) {
  width: 100%;
}

@media only screen and (max-width: 599px) {
  .overlay {
    overflow-y: scroll;
    text-align: start;
    align-items: flex-start;
    /* padding-top: 32px; */
  }

  .overlay::-webkit-scrollbar {
    display: none;
  }

  .overlay {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .form-wrapper {
    width: 100%;
  }

  .form-wrapper .logo {
    margin: 0;
  }
}
</style>