import QRCodeStyling from "qr-code-styling";

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj))

export const pick = (obj, keys) => Object.fromEntries(
    keys.map(key => [key, obj[key]])
);

export const resolvePath = (object, path, defaultValue, separator = ".") => path
    .split(separator)
    .reduce((o, p) => o ? o[p] : defaultValue, object)

export const getImageFileDimensions = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const dataUrl = reader.result
            const img = new Image();
            img.src = dataUrl;
            img.onload = function () {
                resolve({ width: this.width, height: this.height });
            };
            img.onerror = reject;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    })
}

export const flattenObject = (obj) => {
    const object = Object.create(null);
    const path = [];
    const isObject = (value) => Object(value) === value;
    function dig(obj) {
        for (let [key, value] of Object.entries(obj)) {
            path.push(key);
            // if (isObject(value)) dig(value);
            if (isObject(value) && !Array.isArray(value)) dig(value);
            else object[path.join('.')] = value;
            path.pop();
        }
    }
    dig(obj);
    return object;
}

export const diffFlatten = (oldFlat, newFlat) => {
    const changed = Object.assign({}, newFlat);
    for (let key in newFlat) {
        if (!Array.isArray(newFlat[key]) && (newFlat[key] === oldFlat[key])) delete changed[key];
    }
    return changed;
}

export const unflatenObject = (flattenObject) => {
    const unFlatten = Object.create(null);
    for (let [stringKeys, value] of Object.entries(flattenObject)) {
        let chain = stringKeys.split('.')
        let object = unFlatten
        for (let [i, key] of chain.slice(0, -1).entries()) {
            if (!object[key]) {
                let needArray = Number.isInteger(Number(chain[+i + 1]))
                object[key] = needArray ? [] : Object.create(null)
            }
            object = object[key];
        }
        let lastkey = chain.pop();
        object[lastkey] = value;
    }
    return unFlatten;
}

export const generateQrCode = (data, config) => {
    const extension = (svg, options) => {
        const linkWithoutProtocol = options.data.split("://", 2)[1]
        const txt = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "text"
        );
        txt.innerHTML = linkWithoutProtocol;
        txt.setAttributeNS(null, "font-size", "35");
        txt.setAttributeNS(null, "fill", options.dotsOptions.color);
        txt.setAttributeNS(null, "x", "50%");
        txt.setAttributeNS(null, "y", options.height - options.margin / 1.75);
        txt.setAttributeNS(null, "dominant-baseline", "middle");
        txt.setAttributeNS(null, "text-anchor", "middle");
        svg.appendChild(txt);
    };

    const qrCode = new QRCodeStyling({
        ...config,
        data,
        type: "svg",
        width: 1000,
        height: 1000,
        margin: 75,
    });

    qrCode.applyExtension(extension);

    return qrCode
};

export const debounce = (fn, wait) => {
    let timer;
    return function (...args) {
        if (timer) {
            clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(() => {
            fn.apply(context, args); // call the function if time expires
        }, wait);
    }
}

export const throttle = (fn, wait) => {
    let throttled = false;
    return function (...args) {
        if (!throttled) {
            fn.apply(this, args);
            throttled = true;
            setTimeout(() => {
                throttled = false;
            }, wait);
        }
    }
}

export const wait = (ms) => new Promise((res) => setTimeout(res, ms));

export const getFileIcon = (fileType) => {
    if (!fileType) return "mdi-file";
    if (fileType.startsWith("image/")) return "mdi-image";
    if (fileType.startsWith("video/")) return "mdi-movie";
    if (fileType === "text/plain") return "mdi-text-box";
    if (fileType === "application/pdf") return "mdi-file-pdf-box";
    if (
        fileType === "application/msword" ||
        fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    )
        return "mdi-file-word";
    if (
        fileType === "application/vnd.ms-excel" ||
        fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
        return "mdi-file-excel";
    if (
        fileType === "application/vnd.ms-powerpoint" ||
        fileType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    )
        return "mdi-file-powerpoint";
    if (fileType === "application/xml" || fileType === "text/xml")
        return "mdi-xml";
    if (
        fileType === "application/x-7z-compressed" ||
        fileType === "application/zip" ||
        fileType === "application/vnd.rar"
    )
        return "mdi-zip-box";
    return "mdi-file";
}