<template>
  <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="transparent" flat tile>
          <v-btn icon @click="$emit('close-dialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              selectedId
                ? $t("moveXAssets", { assets: assetStore.selected.length })
                : $t("selectDestination")
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="!selectedId"
            :loading="moveLoading"
            @click="move()"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="px-4">
        <v-treeview
          item-key="_id"
          :items="assets"
          :load-children="fetchChildAssets"
          :active.sync="active"
          :open.sync="open"
          disable-per-node
          activatable
          rounded
          hoverable
          loading-icon="mdi-loading"
          expand-icon="mdi-chevron-down"
          color="primary"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon
              class="mr-2"
              :class="item.disabled ? 'text--disabled' : null"
            >
              {{ open ? "mdi-folder-open" : "mdi-folder" }}
            </v-icon>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "MoveComponent",
  props: ["dialog"],
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, assetStore, snackbarStore };
  },
  data() {
    return {
      moveLoading: false,
      active: [],
      open: [],
      assets: [],
    };
  },
  computed: {
    selectedId() {
      if (!this.active.length) return null;
      return this.active[0];
    },
  },
  watch: {
    "assetStore.selected"() {
      const refreshIsDisabled = (children) => {
        children.forEach((child) => {
          child.disabled = this.isDisabled(child);
          if (child.children.length > 0) refreshIsDisabled(child.children);
        });
      };
      refreshIsDisabled(this.assets[0].children);
    },
  },
  async created() {
    this.assets = [
      {
        _id: "root",
        name: this.$t("assets"),
        children: [],
        disabled:
          !this.assetStore.parentAssetId ||
          !this.accountStore.checkAccountPermissions("moveAsset", ""),
      },
    ];
    this.open = ["root"];
  },
  methods: {
    isDisabled(item) {
      if (!item.capabilities.moveAsset) return true; // nedostatocne prava
      if (item._id === this.assetStore.parentAssetId) return true; // polozka sa uz v tomto priecinku nachadza

      const selectedAssetIds = this.assetStore.selected;
      if (selectedAssetIds.includes(item._id)) return true; // priecinok nemozno presunut do seba sameho
      if (selectedAssetIds.some((v) => item.path.includes(v))) return true; // priecinok nemozno presunut do seba sameho

      return false;
    },
    async fetchChildAssets(item) {
      let assets;
      if (item._id === "root") {
        const resp = await this.assetStore.fetchFolders();
        assets = resp.data.assets;
      } else {
        const resp = await this.assetStore.fetchAssetsFolders(item._id);
        assets = resp.data.asset.childAssets;
      }
      item.children = assets.map((asset) => ({
        ...asset,
        children: [],
        disabled: this.isDisabled(asset),
      }));
    },
    async move() {
      try {
        this.moveLoading = true;

        const destinationAssetId =
          this.selectedId === "root" ? null : this.selectedId;
        let refreshAssetRoute = false;
        if (this.assetStore.selectedAssetId === this.assetStore.parentAssetId)
          refreshAssetRoute = true;

        const resp = await this.assetStore.moveSelected(destinationAssetId);

        this.moveLoading = false;
        if (resp) {
          this.$emit("close-dialog");
          this.snackbarStore.text = this.$t(`assetsMovedSuccessfully`);
          if (refreshAssetRoute)
            this.assetStore.refresh = !this.assetStore.refresh;
        } else this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
</style>