var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-wrapper"},[_c('v-autocomplete',{attrs:{"search-input":_vm.search,"label":`${_vm.$t('search')}...`,"placeholder":`${_vm.$t('search')}...`,"menu-props":{
      transition: 'slide-y-transition',
      rounded: 'lg',
      nudgeTop: -4,
      maxHeight: 'auto',
      minWidth: 196,
      bottom: true,
      closeOnContentClick: true,
    },"item-text":"name","filled":"","flat":"","dense":"","rounded":"","hide-details":"","single-line":"","auto-select-first":"","elevation":"0","autofocus":_vm.autofocus === true ? true : false},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.loading)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","size":24,"color":_vm.$vuetify.theme.dark ? 'white' : 'rgba(0,0,0,.54)'}}):_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-magnify")])]},proxy:true},{key:"no-data",fn:function(){return [(_vm.items.length === 0)?_c('div',{staticClass:"no-data-text pa-4 py-3"},[_vm._v("\n        "+_vm._s(_vm.$t("noData"))+"\n      ")]):_c('div',[_c('v-virtual-scroll',{attrs:{"items":_vm.items,"height":_vm.virtualScrollHeight,"width":"500","bench":"5","item-height":_vm.mobile === true ? 56 : 40},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-list-item',{key:item._id,attrs:{"replace":"","link":"","to":`/${item._id}`},on:{"click":function($event){return _vm.$emit('link-clicked')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":_vm.mobile === true ? false : true}},[_vm._v(_vm._s(item.type === "folder" ? "mdi-folder" : "mdi-file"))]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlight(item.name, _vm.search))}})],1)]}}])})],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{class:_vm.mobile === true ? 'qr-scanning-button mobile' : 'qr-scanning-button',attrs:{"small":_vm.mobile === true ? false : true},on:{"click":function($event){_vm.qrScanning = true}}},[_vm._v("mdi-qrcode")])]},proxy:true}]),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}}),_vm._v(" "),_c('QrScanner',{attrs:{"qrScanning":_vm.qrScanning},on:{"change-qr-scanning":function($event){_vm.qrScanning = $event},"qr-decode":_vm.handleDecodedQr}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }