<template>
  <v-skeleton-loader
    :loading="loading"
    type="list-item-two-line, list-item-two-line"
    class="pa-0"
  >
    <template v-slot:default>
      <v-responsive
        v-if="notificationStore.currentAssetNotifications.length === 0"
        :height="noNotificationsNoticeWrapperHeight"
        class="d-flex justify-center align-center"
      >
        <div class="text-center">
          <div
            :class="
              $vuetify.theme.dark
                ? 'text-h5 mb-1 white--text'
                : 'text-h5 mb-1 initial-color'
            "
          >
            {{ $t("noNotifications") }}
          </div>
          <div class="body-2 text--secondary mb-4">
            {{ $t("youHaveNoNotificationsForThisAsset") }}
          </div>
          <v-btn
            small
            rounded
            depressed
            outlined
            color="primary"
            @click="$emit('create-new-notification')"
          >
            <v-icon left>mdi-bell-plus</v-icon>
            {{ $t("newNotification") }}</v-btn
          >
        </div>
      </v-responsive>
      <v-list v-else class="pa-0">
        <v-list-item
          v-for="notification in notificationStore.currentAssetNotifications"
          :key="notification._id"
          class="pa-0"
        >
          <v-list-item-icon>
            <v-icon v-if="notification.sentTimestamp" disabled
              >mdi-bell-check</v-icon
            >
            <v-icon v-else>mdi-bell</v-icon>
          </v-list-item-icon>
          <v-list-item-content
            :class="notification.sentTimestamp ? 'py-0 text--disabled' : 'py-0'"
          >
            <v-list-item-title>{{ notification.name }}</v-list-item-title>
            <v-list-item-subtitle
              v-if="notification.sentTimestamp"
              class="caption"
            >
              <span v-if="notification.sentStatus === 'success'">
                {{ $t("deliveredOn") }}
                {{ new Date(notification.sentTimestamp).toLocaleString() }}
              </span>
              <span v-else>
                {{ $t("failedToDeliverNotification") }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else class="caption"
              >{{ $t("scheduledFor") }}
              {{
                new Date(notification.scheduledAtTimestamp).toLocaleString()
              }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              :loading="loadingNotificationId === notification._id"
              @click="$emit('view-notification', notification._id)"
            >
              <v-icon>mdi-information-variant</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </v-skeleton-loader>
</template>

<script>
import { useNotificationStore } from "@/stores/notification";

export default {
  name: "AssetAccountList",
  props: ["assetId", "loadingNotificationId"],
  setup() {
    const notificationStore = useNotificationStore();
    return { notificationStore };
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    noNotificationsNoticeWrapperHeight() {
      if (this.$vuetify.breakpoint.smAndDown)
        return this.$vuetify.breakpoint.height - 56 - 8 - 20;
      return this.$vuetify.breakpoint.height - 64 - 8 - 20;
    },
  },
  async created() {
    await this.notificationStore.queryNotifications(this.assetId);
    this.loading = false;
  },
};
</script>

<style scoped>
.initial-color {
  color: initial;
}

:deep(.v-skeleton-loader__list-item-two-line) {
  padding: 0;
}
</style>
