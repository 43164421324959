import { defineStore } from 'pinia'
import axios from "axios"
import localforage from 'localforage'
import { API_URL } from '../api.js'
import { flattenObject, diffFlatten, unflatenObject } from "../util/helpers";

export const useAccountStore = defineStore("account", {
    state: () => ({
        account: {},
        subAccounts: []
    }),
    getters: {
        isLoggedIn: state => !!state.account._id,
        isVerified: state => !!state.account.isVerified,
        isSubAccount: state => !!state.account.subAccountOf,
        accountTier: state => state.account.subscription && state.account.subscription.tier ? state.account.subscription.tier : null,
    },
    actions: {
        async persist() {
            await localforage.setItem("accountStore", this.$state)
        },
        async register(account) {
            try {
                const resp = await axios.post(`${API_URL}/accounts`, account, { withCredentials: true })
                const csrfToken = resp.headers["x-csrf-token"];
                localStorage.setItem("csrfToken", csrfToken);
                axios.defaults.headers.common["x-csrf-token"] = csrfToken;
                const data = resp.data;

                this.account = data.account
                await this.persist()

                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async login(email, password) {
            try {
                const resp = await axios.post(`${API_URL}/sessions`, { email, password }, { withCredentials: true })
                const csrfToken = resp.headers["x-csrf-token"];
                localStorage.setItem("csrfToken", csrfToken);
                axios.defaults.headers.common["x-csrf-token"] = csrfToken;
                const data = resp.data;

                this.account = data.account
                await this.persist()

                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async logout() {
            try {
                const resp = await axios.delete(`${API_URL}/sessions`, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
            finally {
                localStorage.removeItem("csrfToken");
                delete axios.defaults.headers.common["x-csrf-token"];
                this.account = {}
                await this.persist()
            }
        },
        async fetchAccount() {
            try {
                const resp = await axios.get(`${API_URL}/accounts/${this.account._id}`, { withCredentials: true })
                const data = resp.data;

                this.account = data.account
                await this.persist()

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchAccountStorageUsage() {
            try {
                const resp = await axios.get(`${API_URL}/accounts/${this.account._id}/storage-usage`, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async upgradeSubscription(tier, billingPeriod) {
            try {
                const returnUrl = window.location.href.split("?")[0]
                const resp = await axios.post(`${API_URL}/accounts/${this.account._id}/upgrade`, { tier, billingPeriod, returnUrl }, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async manageSubscription() {
            try {
                const returnUrl = window.location.href.split("?")[0]
                const resp = await axios.post(`${API_URL}/accounts/${this.account._id}/stripe-portal`, { returnUrl }, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async update(account) {
            try {
                const flatOldAccount = flattenObject(this.account)
                const flatNewAccount = flattenObject(account)
                const flatDiff = diffFlatten(flatOldAccount, flatNewAccount)
                const resp = await axios.patch(`${API_URL}/accounts/${this.account._id}`, flatDiff, { withCredentials: true })

                const flatUpdatedAccount = flatOldAccount
                for (const key in flatDiff) {
                    flatUpdatedAccount[key] = flatDiff[key]
                }
                const updatedAccount = unflatenObject(flatUpdatedAccount)
                this.account = updatedAccount
                await this.persist()

                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async uploadQrImage(image) {
            try {
                const resp = await axios.post(`${API_URL}/accounts/${this.account._id}/upload-qr-image`, { image }, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async removeQrImage() {
            try {
                const resp = await axios.delete(`${API_URL}/accounts/${this.account._id}/remove-qr-image`, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async uploadLogoImage(image) {
            try {
                const resp = await axios.post(`${API_URL}/accounts/${this.account._id}/upload-logo-image`, { image }, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async removeLogoImage() {
            try {
                const resp = await axios.delete(`${API_URL}/accounts/${this.account._id}/remove-logo-image`, { withCredentials: true })
                this.account.logo = { s3Key: null, url: null }
                await this.persist()
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async generateSubAccountInvitationLink(invitation) {
            try {
                const resp = await axios.post(`${API_URL}/accounts/${this.account._id}/generate-subaccount-invitation-link`, { invitation }, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async acceptInvitation(invitationToken) {
            try {
                const resp = await axios.post(`${API_URL}/accounts/${this.account._id}/accept-invitation`, { invitationToken }, { withCredentials: true })
                const data = resp.data;

                this.account.subAccountOf = data.account.subAccountOf
                this.account.subAccountPermissions = data.account.subAccountPermissions
                await this.persist()

                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async fetchSubAccounts() {
            try {
                const resp = await axios.get(`${API_URL}/accounts/${this.account._id}/accounts`, { withCredentials: true })
                const data = resp.data;

                this.subAccounts = data.accounts
                await this.persist()

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async updateSubAccountPermissions(account) {
            try {
                const resp = await axios.patch(`${API_URL}/accounts/${this.account._id}/accounts/${account._id}`, account, { withCredentials: true })
                const accountIndex = this.subAccounts.findIndex(e => e._id === account._id)
                if (accountIndex > -1) {
                    this.subAccounts[accountIndex].subAccountPermissions = account.subAccountPermissions
                    await this.persist()
                }
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async removeSubAccount(accountId) {
            try {
                const resp = await axios.delete(`${API_URL}/accounts/${this.account._id}/accounts/${accountId}`, { withCredentials: true })
                const accountIndex = this.subAccounts.findIndex(e => e._id === accountId)
                if (accountIndex > -1) {
                    this.subAccounts.splice(accountIndex, 1)
                    await this.persist()
                }
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async forgotPassword(email) {
            try {
                const resp = await axios.post(`${API_URL}/accounts/forgot-password`, { email }, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        async changePassword(token, password) {
            try {
                const resp = await axios.post(`${API_URL}/accounts/change-password`, { token, password }, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        checkAccountPermissions(permission, path, newPath = null) {
            try {
                const account = this.account;
                if (!permission || (this.isLoggedIn && !account.subAccountOf)) return true
                const hasPermission = account.subAccountPermissions[permission].hasPermission
                const scopePaths = account.subAccountPermissions[permission].scopePaths
                if (hasPermission && scopePaths.includes("")) return true
                if (hasPermission && (path === "" || newPath === "")) return scopePaths.includes("")
                return hasPermission && scopePaths.some(e => newPath !== null ? path.startsWith(e) && newPath.startsWith(e) : path.startsWith(e))
            } catch (error) {
                return false
            }
        }
    }
})