<template>
  <v-speed-dial
    v-model="fab"
    direction="top"
    transition="slide-y-reverse-transition"
    fixed
    right
    bottom
  >
    <template v-slot:activator>
      <v-btn v-model="fab" dark fab>
        <v-icon>{{ fab ? "mdi-close" : "mdi-dots-vertical" }}</v-icon>
      </v-btn>
    </template>
    <v-speed-dial
      v-model="speedDialLocale"
      direction="left"
      transition="slide-x-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="speedDialLocale"
          fab
          small
          outlined
          @click.stop="speedDialLocale = true"
        >
          <v-icon>mdi-earth</v-icon>
        </v-btn>
      </template>
      <div class="language-buttons-wrapper">
        <v-btn
          fab
          small
          outlined
          @click="changeLocale('sk')"
          :input-value="$i18n.locale === 'sk'"
          active-class="primary--text"
        >
          SK
        </v-btn>
        <v-btn
          fab
          small
          outlined
          @click="changeLocale('en')"
          :input-value="$i18n.locale === 'en'"
          active-class="primary--text"
        >
          EN
        </v-btn>
      </div>
    </v-speed-dial>
    <v-btn fab small outlined @click="changeTheme()">
      <v-icon>{{
        $vuetify.theme.dark
          ? "mdi-lightbulb-variant-outline"
          : "mdi-weather-night"
      }}</v-icon>
    </v-btn>
    <v-btn fab small outlined to="/login">
      <v-icon>mdi-account</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useAssetStore } from "@/stores/asset";

export default {
  name: "NotLoggedInMobileFab",
  setup() {
    const accountStore = useAccountStore();
    const assetStore = useAssetStore();
    return { accountStore, assetStore };
  },
  data() {
    return {
      fab: false,
      speedDialLocale: false,
    };
  },
  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang;
      localStorage.locale = this.$i18n.locale;
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.theme = this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style scoped>
.language-buttons-wrapper {
  display: flex;
  position: absolute;
  right: 52px;
}
</style>